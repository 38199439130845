@import "responsive.scss";
@import "reset.scss";







.form-group {
  margin-bottom: 35px;

  label.control-label {
    display: block;
    color: var(--app-text-color);
    font-size: 14px;
    padding: 0 0 5px;
    margin-bottom: 3px;
    font-weight: 400;
  }
}


label.control-label {
  display: block;
  color: var(--app-text-color);
  font-size: 14px;
  padding: 0 0 5px;
  margin-bottom: 3px;
  font-weight: bold;
}


textarea {
  padding: 15px;
  border-radius: 3px;
  background-color: var(--app-input-bg-color);
  border: 1px solid var(--app-input-border-color);
  min-height: 100px;
  color: var(--app-text-color);

  &:hover,
  &:focus {
    border: 1px solid var(--app-input-border-focus-color) !important;
    outline: none;
    background-color: var(--app-input-bg-color);
  }

  &:focus-visible {
    box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, .25);
  }

  &::placeholder {
    color: var(--app-text-color);
  }
}




.input-field {
  box-sizing: border-box;
  width: 100%;
  padding: 0 15px;

  color: var(--app-text-color);
  @include border-radius(3px);
  background-color: var(--app-input-bg-color);
  border: 1px solid var(--app-input-border-color);

  &:hover,
  &:focus {
    color: var(--app-text-color);
    background-color: var(--app-input-bg-color);
    border: 1px solid var(--app-input-border-focus-color);
  }
}


.form-control {
  font-size: 14px;
  border-radius: 0;
  min-height: 40px;
  height: auto;
  border-radius: 3px;
  background-color: var(--app-input-bg-color);
  border: 1px solid var(--app-input-border-color);
  color: var(--app-text-color);

  &:hover,
  &:focus {
    color: var(--app-text-color);
    background-color: var(--app-input-bg-color);
    border: 1px solid var(--app-input-border-focus-color);
  }


  &:disabled {
    background-color: var(--app-input-bg-color);
    opacity: .7;
  }


  &::placeholder {
    color: var(--app-text-color);
    opacity: .7;
    /* Firefox */
  }

  &::-ms-input-placeholder {
    /* Edge 12 -18 */
    color: var(--app-text-color);
    opacity: .7;
    /* Firefox */
  }

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px var(--app-input-bg-color) inset !important;
    -webkit-text-fill-color: var(--app-text-color) !important;
  }
}


.dateInput {
  input {
    font-size: 14px;
    border-radius: 0;
    min-height: 40px;
    height: auto;
    border-radius: 3px;
    background-color: var(--app-input-bg-color);
    border: 1px solid var(--app-input-border-color);
    color: var(--app-text-color);

    &:hover,
    &:focus,
    &:focus-visible {
      color: var(--app-text-color);
      background-color: var(--app-input-bg-color);
      border: 1px solid var(--app-input-border-focus-color);
    }
  }
}


// .form-control:focus{
//   box-shadow: none !important;
//   background-color: var(--app-input-bg-color);
//   border: 1px solid var(--app-input-border-color);
//   color: var(--app-text-color);
// }


input::placeholder {
  color: var(--app-text-color) !important;
}






.right_dropdown-menu {
  -webkit-box-shadow: 0px 0px 13px 0px rgba(0, 0, 0, 0.23);
  box-shadow: 0px 0px 13px 0px rgba(0, 0, 0, 0.23);
}

.right_dropdown-menu::before {
  content: "" !important;
  width: 0px !important;
  height: 0px !important;
  position: absolute !important;
  top: -10px !important;
  right: 8px !important;
  border-left: 8px solid transparent !important;
  border-right: 8px solid transparent !important;
  border-bottom: 10px solid var(--app-dropdown-bg-color) !important;
  z-index: 7 !important;

}



.form-control:disabled,
.form-control[readonly] {
  opacity: 1;
}





.tox-tinymce {
  border: 1px solid var(--app-input-border-color) !important;
}

//theme editor styles
.tox .tox-toolbar {
  background: none !important;
  border-bottom: 1px solid var(--app-input-border-color) !important;
}

.tox .tox-edit-area {
  border-top: 1px solid var(--app-input-border-color) !important;
}

.tox-toolbar,
.tox .tox-edit-area__iframe {
  background-color: var(--app-input-bg-color) !important;
}

.tox .tox-tbtn--select {
  color: var(--app-text-color) !important;
}

.tox .tox-tbtn__select-chevron svg {
  fill: var(--app-text-color) !important;
}


.tox .tox-toolbar .tox-tbtn svg {
  fill: var(--app-text-color) !important;
}


.tox:not([dir=rtl]) .tox-toolbar__group:not(:last-of-type) {
  border-right: 1px solid var(--app-input-border-color) !important;
}

.tox .tox-tbtn--enabled,
.tox .tox-tbtn:hover {
  background-color: var(--app-editor-button-color) !important;
}


.tox-form {

  .tox .tox-tbtn svg {
    fill: inherit !important;
  }

  .tox-tbtn:hover {
    background-color: inherit !important;
  }

}


//ngx date picker ui 

.md-drppicker {
  background-color: var(--app-ngxpicker-table-color) !important;

  .calendar-table {
    background-color: var(--app-ngxpicker-table-color) !important;
    border-color: var(--app-ngxpicker-table-color) !important;
  }

  th {
    color: var(--app-ngxpicker-table-head-color) !important;
  }

  td {
    line-height: 2 !important;
    border-radius: 0 !important;

    &.off,
    .off.in-range,
    .off.start-date,
    .off.end-date {
      background-color: var(--app-ngxpicker-table-color) !important;
    }
  }

  td.active,
  td.active:hover,
  td.available:hover,
  th.available:hover {
    background-color: var(--primary-color) !important;
  }
}




.form-control {
  appearance: auto !important;
}




select.form-control {
  option {
    background: var(--app-input-bg-color) !important;
    color: var(--app-text-color);
  }
}

.cssOptions {
  background: var(--app-input-bg-color);
  box-sizing: border-box;
  width: 100%;

  color: var(--app-text-color);
  @include border-radius(3px);

  select {
    background-color: var(--app-input-bg-color);
    border: 1px solid var(--app-input-border-color);
    appearance: auto !important;

    width: 100%;
    // padding: 10px 15px !important;
    padding: 10px 30px 10px 15px !important;
    font-size: 14px;
    font-weight: 400;
    color: var(--app-input-placeholder-color) !important;
    border-radius: 5px;


    option {
      background-color: var(--app-input-bg-color);
      color: var(--app-text-color);
      font-size: 12px;
      padding: 3px;
    }

    &:focus,
    &:active {
      color: var(--app-text-color) !important;
      outline: none;
      border: 1px solid var(--app-input-border-focus-color);
    }

    &.disabled {
      cursor: not-allowed;
    }
  }
}

.form-buttons {
  text-align: center;
  width: 100%;


  &.left {
    text-align: left !important;

    button,
    input {
      margin: 0 10px 0 0 !important;
    }
  }

  span {
    font-size: 16px;
    font-weight: 600;
    padding: 0 20px;
    display: block;
    padding-bottom: 20px;

    @include tablet {
      display: inline-block;
      padding-bottom: 0px;
    }
  }
}

.submit-btn {
  font-size: 16px;
  background: var(--primary-color);
  color: $white;
  padding: 12px 27px;
  @include border-radius(5px);
  cursor: pointer;
  border: none;
  // display: block;
  margin: 10px;
  margin-bottom: 0;
  min-width: 150px;

  &.save:before {
    content: "+";
    font-size: 17px;
    margin-right: 5px;
  }

  &.new {
    background: #038D87;
  }

  &:hover {
    background: var(--primary-hover-color);
    color: white;
    @include transitions(0.5s);
  }

  &.cancel {
    background: var(--comp-bg-color);
    color: var(--app-text-color);
    border: 1px solid var(--border-light-color);
  }

  &:disabled {
    opacity: 0.5;
  }

  @include tablet {
    display: inline-block;
    text-align: center;
  }
}





/*Dropdown*/


.ng-select {

  background: var(--app-input-bg-color) !important;

  &:hover,
  &:focus {
    border: none !important;
  }

  .ng-select-container {
    background: var(--app-input-bg-color) !important;
    border: 1px solid var(--app-input-border-color);
    min-height: 40px !important;
    padding: 0 !important;

    .ng-value-container {
      padding-top: 5px !important;

      .ng-placeholder {
        color: var(--app-text-color);
        font-size: 14px !important;
        padding-top: 11px !important;
        top: 0 !important;
        opacity: .7 !important;
      }

      input {
        color: var(--app-text-color) !important;
      }

      .ng-value {
        background-color: var(--input-select-tag-bg-color) !important;
        color: var(--app-text-color) !important;
        font-size: 12px !important;
        border: none !important;
        padding: 4px 8px !important;
        border-radius: 5px !important;

        .ng-value-label {
          color: var(--app-text-color) !important;
        }

        .ng-value-icon.left {
          border-right: 1px solid var(--app-input-border-color) !important;
        }

      }

      .ng-value-icon {
        &:hover {
          background-color: var(--input-select-tag-hover-bg-color) !important;
        }
      }
    }
  }

  &.ng-select-single {

    .ng-value-container {
      padding-top: 0 !important;

      .ng-placeholder {

        padding-top: 0 !important;

      }
    }

  }

  .ng-arrow-wrapper .ng-arrow {
    border-color: var(--app-text-color) transparent transparent;
  }

  &.ng-select-opened .ng-select-container .ng-arrow {
    border-color: transparent transparent var(--app-text-color);
  }

  .ng-select.ng-select-opened>.ng-select-container {
    border: 1px solid var(--app-input-border-focus-color) !important;
  }



  &.ng-select-multiple .ng-select-container .ng-value-container .ng-input>input,
  .ng-clear-wrapper {
    color: var(--app-text-color);
  }


  .ng-dropdown-panel {
    border: 1px solid var(--app-input-border-color) !important;
  }

  .ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
    background-color: var(--select-dropdown-bg-color);
    color: var(--app-text-color);
    text-align: left;

    &:hover {
      color: var(--primary-color);
    }

  }


}


.ng-select.ng-select-opened>.ng-select-container {
  border-color: var(--app-input-border-color) !important;
}

.tagSelect {
  padding: 0 !important;
  border: none;

  &.is-invalid {
    padding: 0 !important;

    .ng-select-container {
      border: none !important;
    }
  }


  .ng-select-container {
    // min-height: 43px;
    // border: 1px solid #e1e1e1 !important;

    .ng-placeholder {
      top: 12px !important;
      color: var(--app-text-color);
    }
  }

  .ng-value {

    // border-radius: 15px !important;
    // overflow: hidden;
    // // background: #3577e4 !important;
    // font-size: 14px;
    // padding: 3px 5px;

    span {
      // background: #3577e4 !important;
      // color: #fff !important;
    }
  }
}


.customSelectDark {
  background: var(--app-reports-form-control-color) !important;

  .ng-select {
    .ng-select-container {
      background: none !important;
      border: none !important;
      min-height: 40px !important;

      .ng-value-container {

        .ng-placeholder {
          color: var(--app-text-color);
        }
      }
    }
  }
}




// .appForm{

//   label{
//       font-size: 16px;
//       font-weight: 400;
//       color: #343434;
//       margin-bottom: 5px;
//   }

//   input[type="text"], 
//   input[type="email"], 
//   input[type="url"], 
//   input[type="number"], 
//   input[type="tel"], 
//   input[type="password"], 
//   input[type="file"], 
//   select, 
//   textarea{
//       width: 100%;
//       background:#fdfdfd;
//       border: 1px solid #ededed;
//       height:50px;
//       padding: 5px 15px !important; 
//        border-radius: 3px;
//       font-size: 14px;
//       font-weight: 400;
//       color:#343434;
//       box-shadow: none;
//       outline:none;
//       &:focus{
//            border: 1px solid #3b85ff61;
//            background-color: #fdfdfd;
//            box-shadow:none;
//            outline: none;
//       }
//   }
//   textarea{
//       height:130px !important;
//       padding: 10px 15px !important; 
//       resize: none;
//   }

//   select{
//       height: 50px !important;
//       line-height: 50px;
//   }

//   input[type="submit"] {
//       display: inline-block;
//       min-width: 140px;
//       text-align: center;
//       background: #3577e4;
//       font-size: 18px;
//       color: #fff;
//       border: none;
//       font-weight: 400;
//       padding:12px 30px;
//       border-radius: 3px;
//       outline: none;
//       cursor: pointer;


//       &:hover,  &:focus {
//         background:#2e6bd0;
//         color: #fff;
//       }
//   }

//   .form-text{
//       font-size: 12px;
//       margin-top: 4px;
//   }

//   :-webkit-autofill,
//   :-webkit-autofill:hover, 
//   :-webkit-autofill:focus, 
//   :-webkit-autofill:active  {
//       -webkit-text-fill-color: #343434 !important;
//       transition: background-color 5000s ease-in-out 0s;
//   }


// }



.condition-des-div {
  padding: 15px;
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  background-color: var(--app-input-bg-color);
  border: 1px solid var(--app-input-border-color);
  color: var(--app-text-color);
  max-height: 200px;
  border-radius: 3px;
  overflow-y: auto;
}




.multiSetpTogglebtn {
  color: var(--app-text-color) !important;

  mat-slide-toggle {
    color: var(--app-text-color) !important;
  }


  .mdc-form-field {
    label {
      color: var(--app-text-color) !important;
    }
  }

}


.md-drppicker .btn.btn-default {
  color: var(--app-text-color) !important;
  background-color: var(--app-input-bg-color) !important;
}

.md-drppicker .clear svg {


  width: 19px !important;
  margin-top: -8px !important;
}

.md-drppicker .btn {
  background-color: var(--primary-color) !important;
}


// .tox .tox-dialog{
//   background-color: red !important;
// }


.tox .tox-split-button:hover {
  box-shadow: none !important;
}



:root .mdc-menu-surface {
  background-color: var(--app-input-bg-color) !important;
  color: var(--app-text-color) !important;

  .mat-mdc-option {
    span {
      color: var(--app-text-color) !important;

    }

    &.mat-mdc-option-active {
      span {
        color: var(--primary-color) !important;
      }
    }
  }
}