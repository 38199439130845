/*Column Selector*/


.columnSelectorWrapper {


    .dropdown-menu {
        background: var(--comp-bg-color);
        box-shadow: 0px 4px 8px 4px rgba(0, 0, 0, 0.1);
        min-width: 200px !important;
        margin-top: 0.5rem;
        left: auto !important;
        right: 0 !important;
        max-height: 400px;
        overflow-x: auto;


        &::-webkit-scrollbar {
            width: 6px;
            height: 6px;
            border-radius: 6px;
        }

        /* Track */
        &::-webkit-scrollbar-track {
            background: var(--scrollbar-track-color);
        }

        /* Handle */
        &::-webkit-scrollbar-thumb {
            background: var(--scrollbar-thumb-color);
        }

        /* Handle on hover */
        &::-webkit-scrollbar-thumb:hover {
            background: var(--scrollbar-thumb-hover-color);
        }

        scrollbar-color: var(--scrollbar-track-color) var(--scrollbar-thumb-color);
        scrollbar-width: thin;

    }


    .column-selector {

        &::before {
            content: "" !important;
            width: 0px !important;
            height: 0px !important;
            position: absolute !important;
            top: -10px !important;
            right: 8px !important;
            border-left: 8px solid transparent !important;
            border-right: 8px solid transparent !important;
            border-bottom: 10px solid var(--app-dropdown-bg-color) !important;
            z-index: 7 !important;
        }

        ul {
            li {
                border-bottom: 1px solid var(--border-light-color);
                padding: 10px 15px 7px 15px;
                font-size: 13px;
                color: var(--app-text-color);

                .form-check {
                    min-height: 10px !important;

                    input {
                        margin-top: 0 !important;
                    }

                    label {
                        margin-bottom: 0 !important;
                        text-transform: capitalize;
                    }

                }
            }
        }
    }
}




/*List Filter*/




.filterDropdownBtn {
    transition: 350ms;
    border: none;

    &.dropdown-toggle::after {
        color: var(--app-text-color);
    }

    svg {
        width: 25px;
        height: 25px;
        display: inline-block;
        margin-right: 5px;
        fill: var(--primary-color);
    }

}


.listFilterDropdown {
    padding: 30px;
    right: 0 !important;
    background: var(--app-dropdown-bg-color);

    h6 {
        font-weight: 500 !important;
        font-size: 16px !important;
        color: var(--app-text-color);
        margin-bottom: 0;
    }


    .listFilterHeader {
        margin-bottom: 30px;
    }

    .listFilterBody {

        label {
            color: var(--app-text-color) !important;
        }

        .cssOptions,
        ng-select {
            margin-bottom: 10px;
        }
    }



    /*Task List Page*/
    &.tastListDropdown {
        .listFilterBody {

            .cssOptions,
            ng-select {
                margin-bottom: 0;
            }
        }
    }

    .listFilterFooter {
        margin-top: 20px;
        padding-top: 10px;
    }


    .filterSaveSetting {
        input {
            margin-top: 2px !important;
        }

        label {
            font-size: 14px;
            margin: 0 !important;
            color: var(--app-text-color);
        }
    }




}



// table old start


.mat-paginator {
    .mat-paginator-container {
        background: #f0f0f0 !important;
    }
}



// table old end