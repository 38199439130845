@import "responsive.scss";
@import "reset.scss";




.dataTables_length {
  // margin-left: 20px;
  padding: 10px;

  label {
    margin-bottom: 0;
    color: var(--app-text-color);
  }

  select {
    color: var(--app-text-color) !important;
    border: 1px solid var(--app-input-border-color) !important;

    option {
      background: var(--app-input-bg-color) !important;
      color: var(--app-text-color) !important;
    }
  }
}


table.dataTable {
  thead {
    background: var(--app-datatable-odd-row-color);
  }
}

// table.dataTable thead th,
// table.dataTable tfoot th {
//   font-size: 14px;
//   font-weight: 500;
//   text-transform: uppercase;
//   color: var(--app-text-color);
// }

// table.dataTable thead th,
// table.dataTable thead td {
//   border-bottom: 1px solid #e4e4e4;
//   padding: 14px 10px;
//   border-top: 1px solid #e4e4e4;
// }

// table.dataTable tbody th,
// table.dataTable tbody td {
//   font-size: 14px;
//   font-weight: 500;
//   padding: 15px;
//   color: var(--app-text-color);
//   word-break: break-all;
// }

table.dataTable.display tbody tr.odd>.sorting_1,
table.dataTable.order-column.stripe tbody tr.odd>.sorting_1 {
  background: none;
}

table.dataTable.display tbody tr.even>.sorting_1,
table.dataTable.order-column.stripe tbody tr.even>.sorting_1 {
  background: none;
}

table.dataTable.display tbody tr.even>.sorting_1,
table.dataTable.order-column.stripe tbody tr.even>.sorting_1 {
  background: none;
}

table.dataTable.stripe tbody tr.odd,
table.dataTable.display tbody tr.odd {
  background: #f9f9f9;
}

table.dataTable.no-footer {
  border-bottom: 1px solid #e4e4e4;
}

.dataTables_info {
  // border-top: 1px solid #e4e4e4;

  font-size: 12px;
  font-weight: 500;
  padding: 20px 15px 10px 0 !important;
  color: #555555;
}

// .dataTables_filter {
//   label {
//       
//       font-size: 12px;
//       font-weight: 500;
//       color: var(--border-light-color);
//   }
// }



.table-holder {
  // width: 1000px;
  min-height: 620px;

  @include desktop {
    // width: auto;
  }
}


.table-holder {

  div.dataTables_length select {
    background-color: var(--app-form-control-color);
  }


}







.dataTables_wrapper {



  .dataTables_filter {
    // margin-right: 20px;
    padding-top: 7px;
    padding-bottom: 5px;

    label {

      font-size: 12px;
      font-weight: 500;
      color: var(--app-text-color);
    }

    label {

      font-size: 14px;
      font-weight: 500;
      color: var(--app-text-color);
    }

    input[type=search] {
      width: auto;
      box-sizing: border-box;
      margin-left: 15px;
      padding: 5px 10px;
      border: 1px solid var(--border-light-color);
      font-size: 14px;
      font-weight: 400;
      color: var(--app-text-color);
      height: 36px;
      outline: none;
      /* border-radius: 5px; */
      @include placeholder(var(--app-text-color), 14px);
      //@include border-radius(5px);

      &:hover,
      &:focus {
        outline: none;
        border: 1px solid var(--app-input-border-focus-color);
      }
    }
  }


  .dataTables_paginate {
    .paginate_button {
      font-size: 14px;
      color: var(--app-text-color);
      padding: 10px 15px;
      text-align: center;
      cursor: pointer;
      background: none;
      border: 1px solid var(--border-light-color);
    }

    .paginate_button.last {
      border-right: 1px solid var(--app-datatable-footer-button-color) !important;
    }
  }


  .dataTables_paginate {
    padding-top: 10px;
    // margin-right: 20px;
    padding-bottom: 10px;
  }


  .dataTables_info {

    font-size: 14px;
    color: var(--app-text-color);
    font-weight: 400;
    // margin-left: 20px;
  }

}


// .table-visual {
//   margin: -20px 0 0;
// }


.dataTables_empty {
  display: none;
}




.dataTables_wrapper .dataTables_paginate .paginate_button.current,
.dataTables_wrapper .dataTables_paginate .paginate_button.current:hover {
  background: var(--primary-color); //background: #f9f9f9;
  // border: 1px solid var(--border-light-color);

  font-size: 13px;
  color: var(--app-text-color) !important;
  text-align: center;
  cursor: pointer;
  margin: 0px;
  // border-right: none;
  padding: 10px 15px;
}

.dataTables_wrapper .dataTables_paginate .paginate_button.disabled,
.dataTables_wrapper .dataTables_paginate .paginate_button.disabled:hover,
.dataTables_wrapper .dataTables_paginate .paginate_button.disabled:active {

  font-size: 13px;
  color: var(--app-text-color) !important;
  text-align: center;
  cursor: pointer;
  background: none;
  border: 1px solid var(--border-light-color);
  margin: 0px;
  // border-right: none;
  padding: 10px 15px;
}



.dataTables_wrapper .dataTables_paginate .paginate_button {

  font-size: 14px;
  color: var(--app-text-color) !important;
  padding: 10px 15px;
  text-align: center;
  cursor: pointer;
  background: none;
  border: 1px solid var(--app-datatable-footer-button-color);
  margin: 0px;
  // border-right: none;
}

.dataTables_wrapper .dataTables_paginate .paginate_button:hover {

  font-size: 14px;
  color: var(--app-text-color) !important;
  padding: 10px 15px;
  text-align: center;
  cursor: pointer;
  background: var(--app-datatable-footer-button-color);
  border: 1px solid var(--app-datatable-footer-button-color);
  margin: 0px;
  border-right: none;
}






.projects-table {
  tr {
    &:nth-child(odd) {
      background: var(--app-datatable-odd-row-color);
    }

    &:nth-child(even) {
      background: var(--app-datatable-even-row-color);
    }

    td {
      text-align: left;
      vertical-align: middle;
      padding: 20px 15px;
      font-size: 14px;
      color: var(--app-text-color);
      font-weight: 400;
      white-space: normal !important;

      span {
        &.user-image {
          display: inline-block;
          margin-right: 20px;

          img {
            width: 46px;
            height: 46px;
            @include border-radius(50%);
          }
        }
      }

      &.heading {
        border-bottom: 1px solid #d9d9df;
        font-size: 14px;
        font-weight: 600 !important;
        color: var(--app-text-color) !important;
        padding: 20px 15px;
      }

    }

    th.heading {
      border-bottom: 1px solid #d9d9df;
      font-size: 14px;
      font-weight: 600 !important;
      color: var(--app-text-color) !important;
      padding: 20px 15px;
      font-family: $font;
      white-space: nowrap;
    }
  }


}





// .no-data-available {
//   text-align: center !important;
//   font-weight: bold !important;
//   font-size: 16px !important;
// }






// material table styling
// -----------------------------------------------------------------------------------------------

// .mat-header-row,
// .mat-mdc-header-row {
//     background: #f0f0f0;
//     .mat-header-cell, .mat-mdc-header-cell{
//       color: var(--app-text-color);
//       font-weight: 700;
//       background: var(--app-mat-table-header-footer-color) !important;
//       font-size: 14px;
//       padding: 0 10px;

//       &:first-child {
//         padding-left: 24px;
//       }
//     }
// }

// .mat-cell,
// .mat-mdc-cell {
//     color: var(--app-text-color) !important;
//     vertical-align: middle;
//     // border: 0 !important;
//     padding: 0 10px !important;

//     &:first-child {
//         padding-left: 24px !important;
//     }
// }

// .non-border-table{
//     .mat-cell,
//     .mat-mdc-cell {
//         border: 0 !important;
//     }
// }

// .mat-row,
// .mat-mdc-row {
//     background: var(--app-mat-table-body-color) !important;
//     &:hover {
//         background: var(--app-mat-table-row-hover-color) !important;
//         .tab-btns {
//             display: flex !important;
//         }
//     }
// }



ul.table-actions23 {

  display: flex;
  margin: 0px;

  li {
    display: inline-block;
    margin: auto 3px;

    a {
      background: var(--app-datatable-action-button-color);
      padding: 5px 8px;
      text-align: center;
      border: 1px solid var(--app-datatable-action-button-color);


      i,
      em {
        font-size: 16px;
        color: var(--app-text-color);
        display: inline-block;
        padding: 0px;
        @include transitions (0.5s);
        vertical-align: middle;

        i,
        em {
          @include transitions (0.5s);
        }
      }

      &:hover {
        background: $violet;
        border: 1px solid $violet;
        color: #fff;

        i,
        em {
          color: white;
        }
      }
    }

  }

}

// .mat-table,
// .mat-mdc-table {
//     background: #f0f0f0;
// }

.mat-paginator,
.mat-mdc-paginator {
  border: 1px solid var(--table-border-color) !important;
  background: var(--mat-table-paginator-bg) !important;
  color: var(--app-text-color) !important;

}

.mat-paginator-container,
.mat-mdc-paginator-container {
  justify-content: space-between !important;
}

.mat-paginator-page-size-label,
.mat-mdc-paginator-page-size-label {
  padding-right: 18px !important;
  padding-left: 18px;
}

.mat-mdc-paginator-icon {
  fill: var(--app-text-color) !important;
}

.mat-mdc-icon-button[disabled] .mat-mdc-paginator-icon {
  fill: var(--app-mat-table-footer-disabled-color) !important;
}

.mat-mdc-select-arrow,
.mat-mdc-select-value-text {
  color: var(--app-text-color) !important;
}

.mdc-notched-outline__leading,
.mdc-notched-outline__trailing {
  border-color: var(--app-text-color) !important;
}

// end material table styling







// Site Table Start



.siteTableWrapper {

  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
    border-radius: 6px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: var(--scrollbar-track-color);
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: var(--scrollbar-thumb-color);
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: var(--scrollbar-thumb-hover-color);
  }

  scrollbar-color: var(--scrollbar-track-color) var(--scrollbar-thumb-color);
  scrollbar-width: thin;
}



.siteTable {
  background: var(--table-bg-color) !important;
  border: 1px solid var(--table-border-color) !important;
  width: 100%;

  thead {
    th {
      background-color: var(--table-th-bg-color) !important;
    }

    th,
    td {
      text-align: left !important;
      border: none !important;
    }
  }


  th {
    background-color: var(--table-th-bg-color) !important;
    font-weight: 400 !important;
    padding: 15px 10px !important;
  }

  td {
    font-weight: 400 !important;
    padding: 10px 10px !important;
  }




  th,
  td {
    text-align: left !important;
    vertical-align: middle;

    font-size: 12px !important;
    color: var(--app-text-color) !important;
    white-space: normal !important;
    background: none;
    border: none;
    // border: 1px solid var(--table-border-color);
    border-color: var(--table-border-color) !important;
    border-left: none;
    border-right: none;

    // &.noRecord {
    //   text-align: center !important;

    //   p {
    //     font-size: 16px !important;
    //     font-weight: 500 !important;
    //     padding-top: 10px;
    //   }
    // }

    strong {
      font-weight: 600 !important;
    }

    p {
      font-size: 12px !important;
      margin-bottom: 0 !important;
      padding: 0;
      background: none !important;
      color: var(--app-text-color) !important;

    }

    a {
      color: var(--app-text-color) !important;
      text-decoration: underline;

      &:hover {
        color: var(--app-text-color) !important;
        text-decoration: underline;
      }
    }

    .no-data-content {
      a {
        color: #fff !important;
        text-decoration: none;

        &:hover {
          color: #fff !important;
          text-decoration: none;
        }
      }
    }

    .mdc-checkbox__background {
      width: 1rem;
      height: 1rem;
    }

  }

  th.mat-mdc-table-sticky-border-elem-right {
    background-color: var(--table-th-bg-color) !important;
    // box-shadow: -3px 1px 2px #0000000f;

    width: 80px !important;
    min-width: 80px;

  }

  td.mat-mdc-table-sticky-border-elem-right {
    background: var(--table-bg-color) !important;
    // box-shadow: -3px 1px 2px #0000000f;

    width: 80px !important;
    min-width: 80px;

  }






  tr {
    &:hover {
      td {
        background-color: var(--table-highlight-bg-color);
      }
    }
  }


  &.no-footer {}


  .dataTables_info {
    border: none !important;
  }


}




.actionColTh {
  width: 80px !important;
  min-width: 80px;
  position: sticky;
  right: 0;
  background-color: var(--table-th-bg-color) !important;
  z-index: 1;
  /* Ensure it appears on top of other cells */
  // box-shadow: -3px 1px 2px #0000000f;
}

.actionColTd {
  width: 80px !important;
  min-width: 80px;
  position: sticky;
  right: 0;
  background: var(--table-bg-color) !important;
  z-index: 1;
  /* Ensure it appears on top of other cells */
  // box-shadow: -3px 1px 2px #0000000f;
}




.siteTableMain {

  table {
    background: var(--table-bg-color) !important;
    border: 1px solid var(--table-border-color) !important;
    width: 100%;
  }

  thead {
    th {
      background-color: var(--table-th-bg-color) !important;
    }

    th,
    td {
      text-align: left !important;
      border: none !important;
    }
  }


  th {
    background-color: var(--table-th-bg-color) !important;
    font-weight: 600 !important;
  }

  td {
    font-weight: 400 !important;
  }

  th,
  td {
    text-align: left !important;
    vertical-align: middle;
    padding: 10px 10px !important;
    font-size: 12px !important;
    color: var(--app-text-color) !important;
    white-space: normal !important;
    background: none;
    border: 1px solid var(--table-border-color);
    border-color: var(--table-border-color) !important;
    border-left: none;
    border-right: none;

    // &.noRecord {
    //   text-align: center !important;

    //   p {
    //     font-size: 16px !important;
    //     font-weight: 500 !important;
    //     padding-top: 10px;
    //   }
    // }

    strong {
      font-weight: 600 !important;
    }

    p {
      font-size: 12px !important;
      margin-bottom: 0 !important;
      padding: 0;
      background: none !important;
      color: var(--app-text-color) !important;

    }

    a {
      color: var(--app-text-color) !important;
      text-decoration: underline;

      &:hover {
        color: var(--app-text-color) !important;
        text-decoration: underline;
      }
    }
  }

  tr {
    &:hover {
      td {
        background-color: var(--table-highlight-bg-color);
      }
    }
  }


  &.no-footer {}


  .dataTables_info {
    border: none !important;
  }


}


.siteTableActions {
  display: block;
  margin: 0px;

  ul {
    padding: 0;
    margin: 0;

    li {
      display: inline-block;

      margin: 2px 5px 2px 0;

      a {
        display: block;
        border-radius: 3px;
        background: var(--table-cta-bg);
        padding: 5px 8px;
        text-align: center;
        font-size: 12px;
        color: var(--app-text-color);

        em,
        i {

          color: var(--app-text-color);
        }

        &:hover {
          background: var(--table-cta-bg-hover);
          color: var(--app-text-color);

          em,
          i {
            color: var(--app-text-color);
          }
        }
      }
    }
  }
}



// Site Table End





/*details pages*/

.detailsTable {
  table {
    border: 1px solid var(--table-border-color);
    background: none !important;

    th,
    td {
      border: 1px solid var(--table-border-color) !important;
      font-size: 14px;
      color: var(--app-text-color);
      padding: 10px 15px;

      p {
        margin: 0 !important;
        padding: 0 !important;
        max-width: 100%;
        display: block;
        color: var(--app-text-color) !important;
        background: none !important;

        p {
          color: var(--app-text-color) !important;
        }
      }

      a {
        background: none !important;
      }

      table {
        margin: 0 !important;
      }

      ul {
        background: none !important;
      }

      &.newSecTitle {
        background: var(--app-details-table-section-color) !important;
      }

      &.newSecContent {
        padding: 4px !important;
      }

      a {
        text-decoration: underline !important;
        font-size: 14px;
        color: var(--app-text-color) !important;
      }

      &.high {
        color: red !important;

        p {
          color: red !important;
        }
      }
    }

    th {
      width: 200px;
      font-weight: 500;
      background: var(--table-th-bg-color);
    }

    td {}
  }


  .breadcrumbStyleSimple {
    margin: 0;
    background: none;

    ol,
    ul {
      margin: 0;
      padding: 0;

      li {
        margin: 0;
        font-size: 14px;
        color: var(--app-text-color);

        a {
          font-size: 14px;
          color: var(--app-text-color);
        }
      }
    }
  }
}


// History Table Start

.tcHistoryTable {
  max-height: 355px;
  overflow-y: auto;
  border: 1px solid var(--table-border-color) !important;

  table {
    background: var(--table-bg-color) !important;
    margin: 0 !important;
    border: none;
    color: transparent !important;

    thead {
      th {
        background-color: var(--table-th-bg-color) !important;
      }

      th,
      td {
        text-align: left !important;
        border: none !important;
      }
    }


    th {
      background-color: var(--table-th-bg-color) !important;
      font-weight: 600 !important;
    }

    td {
      font-weight: 400 !important;
    }

    th,
    td {
      vertical-align: top;
      text-align: left !important;
      padding: 10px 10px !important;
      font-size: 12px !important;
      color: var(--app-text-color) !important;
      white-space: normal !important;
      background: none;
      border: 1px solid var(--table-border-color);
      border-color: var(--table-border-color) !important;


      p {
        font-size: 12px !important;
        margin-bottom: 0 !important;
        padding: 0;

      }

      a {
        color: var(--app-text-color) !important;
        text-decoration: underline;

        &:hover {
          color: var(--app-text-color) !important;
          text-decoration: underline;
        }
      }
    }
  }

  table {

    td.tcUserName {

      max-width: 130px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .logDetails {
    strong {
      display: block;
      margin-bottom: 5px;
      font-weight: 600;
    }


    p {
      font-size: 14px;
      color: var(--app-text-color);
      display: block;
      margin: 3px 0 0 0 !important;
      padding: 0 !important;
    }

    .logPrev {
      display: inline-block;
      opacity: .8;
    }

    .logSep {
      display: inline-block;
      padding: 0 10px;
    }

    .logDetailsTable {
      margin-bottom: 6px !important;
      border: 1px solid var(--table-border-color) !important;
      width: 100%;

      th,
      td {
        padding: 5px;
        background: rgb(0 0 0 / 2%);
        color: var(--app-text-color);

        a {
          color: var(--app-text-color);
          text-decoration: underline !important;
        }
      }

      td {
        &:first-child {
          color: var(--app-text-color);
          width: 48%;
        }

        &:last-child {
          width: 48%;
        }
      }
    }

  }



}




// History Table End







// .noRecord {
//   text-align: center !important;
//   margin-top: 21px;

//   img {
//     height: auto;
//     width: 70px;
//   }

//   p {
//     font-size: 25px;

//     color: var(--app-text-color);
//     font-weight: 400;
//   }

//   .noRecord-P2 {
//     font-size: 18px;

//     color: var(--app-text-color);
//     font-weight: 100;

//     span {
//       color: #fff;
//       background: var(--primary-color);
//       padding: 2px 8px;
//       border-radius: 6px;

//     }
//   }

// }


.table td:not(caption) {
  background-color: transparent !important;
}

.detailsTable,
.siteTableWrapper {
  td {
    .tceExecuteBtn {
      background: var(--primary-color) !important;
      padding: 5px 8px;
      display: inline-block;
      color: #fff !important;
      font-size: 12px;
      font-weight: 400 !important;
      text-decoration: none !important;
      border-radius: 3px;

      &:hover {
        background: var(--primary-hover-color);
      }
    }
  }
}


.truncate-table-text {
  max-width: 200px;
}