// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
@import '../node_modules/@angular/material/theming';
// Plus imports for other components in your app.
// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$my-app-primary: mat.define-palette(mat.$indigo-palette);
$my-app-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$my-app-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$my-app-theme: mat.define-light-theme((color: (primary: $my-app-primary,
                accent: $my-app-accent,
                warn: $my-app-warn )));




@import "../src/assets/css/responsive.scss";
@import "../src/assets/css/theme-colors.scss";


@import "../src/assets/css/dashboard.scss";
@import "../src/assets/css/tables.scss";
@import "../src/assets/css/form.scss";
@import "../src/assets/css/accordion.scss";
@import "../src/assets/css/modules.scss";
@import "../src/assets/css/overlay.scss";

@import "../src/assets/css/login.scss";



@import "../src/assets/css/reset.scss";

@import "@angular/material/prebuilt-themes/purple-green.css";
@import "../node_modules/ngx-smart-modal/styles/ngx-smart-modal.scss";

// @import '~@fullcalendar/core/main.css';
// @import '~@fullcalendar/daygrid/main.css';

@import "../node_modules/angular-calendar/css/angular-calendar.css";
@import "@ng-select/ng-select/themes/default.theme.css";




/*new css files start*/

@import "../src/assets/css/modules/bootstrap-four.scss";
@import "../src/assets/css/modules/main.scss";
@import "../src/assets/css/modules/reports.scss";

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.



:root {
    @include angular-material-color($my-app-theme);
    @include theme ($theme: light);
}

.dark-theme {
    @include theme ($theme: dark);
}




body {
    background-color: var(--main-bg-color);
    overflow-x: hidden;
    outline: none;


    font-family: $font;
    font-weight: 400;
    color: var(--app-text-color);
    font-size: 14px;
    line-height: normal;


    margin: 0px;
    padding: 0px;


    &::-webkit-scrollbar {
        width: 8px;
        border-radius: 8px;
    }

    /* Track */
    &::-webkit-scrollbar-track,
    &::-webkit-scrollbar-corner {
        background: var(--scrollbar-track-color);
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
        background: var(--scrollbar-thumb-color);
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
        background: var(--scrollbar-thumb-hover-color);
    }

    scrollbar-color: var(--scrollbar-track-color) var(--scrollbar-thumb-color);
    scrollbar-width: thin;


}



* {
    &::-webkit-scrollbar {
        width: 6px;
        height: 6px;
        border-radius: 6px;
    }

    /* Track */
    &::-webkit-scrollbar-track,
    &::-webkit-scrollbar-corner {
        background: var(--scrollbar-track-color);
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
        background: var(--scrollbar-thumb-color);
        @include transitions(1s);
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
        background: var(--scrollbar-thumb-hover-color);
        @include transitions(1s);
    }

    scrollbar-color: var(--scrollbar-track-color) var(--scrollbar-thumb-color);
    scrollbar-width: thin;
}


.appPageContentWrapper {
    padding: 20px;
}


hr {
    color: var(--border-dark-color) !important;
}

.fa {
    font: normal normal normal 14px/1 FontAwesome !important;
}

.image-preview {
    >div {
        position: relative;

        .up-img-cross {
            top: -5px;
            line-height: 1;
            left: -5px;
            background: #ff5151;

            .fa-times {
                font-size: 12px !important;
            }
        }
    }
}



a {
    text-decoration: none;
    outline: none !important;
    color: var(--app-text-color);

    &:hover {
        text-decoration: none;
    }

    &:active {
        text-decoration: none;
    }

    &:visited {
        text-decoration: none;
    }
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: normal;
    padding: 0 0 10px;
    margin: 0px;
    line-height: normal;
}

p {
    line-height: normal;
    padding: 0 0 10px;
    margin: 0px;
    color: var(--app-text-color);
    font-size: 14px;
}

// clearing floats //
.clearfix:after {
    content: "&nbsp;";
    font-size: 0;
    display: block;
    height: 0;
    clear: both;
    visibility: hidden;
}

.clearfix {
    display: inline-block;
}

.clearfix {
    display: block;
}

* html .clearfix {
    height: 1%;
}

.clear {
    clear: both;

    &:after {
        display: block;
        content: "";
        display: block;
    }
}

ul {
    display: block;
    margin: 0px;
    padding: 0px;

    li {
        list-style-type: none;
    }
}

.fileUploader {
    .container {
        padding-left: 0px !important;
        padding-right: 0px !important;
    }
}

.mat-radio-outer-circle {
    border-color: rgba(7, 7, 7, 1);
}

.mat-radio-button {
    position: relative;
    margin-right: 20px;
}

.mat-radio-outer-circle {
    top: 5px !important;
}

.mat-radio-inner-circle {
    top: 5px !important;
}

.mat-radio-button.mat-accent .mat-radio-inner-circle {
    background: var(--primary-color);
}

.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
    border-color: var(--primary-color);
}

.mat-tab-labels {
    .mat-ripple-element {
        background-color: rgba(201, 201, 201, 0.1) !important;
    }
}

.mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element {
    background-color: rgba(221, 66, 22, .1) !important;
    top: 5px !important;
}

.mat-checkbox-background {
    background-color: $white;
    border: 1px solid #a6a6a6;
}

.mat-checkbox.mat-accent .mat-checkbox-ripple .mat-ripple-element {
    background-color: rgba(221, 66, 22, .1) !important;
    top: 0px !important;
}

.mat-checkbox-checked.mat-accent .mat-checkbox-background,
.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background {
    background-color: var(--primary-color);
}

.mat-checkbox-mixedmark {
    background-color: $white !important;
}

.mat-checkbox-checked {
    .mat-checkbox-background {
        border: 1px solid var(--primary-color);
    }
}

.mat-checkbox-inner-container {
    margin-top: -6px !important;
}

.mat-checkbox-layout .mat-checkbox-label {
    text-transform: none !important;
}

.mat-checkbox-checkmark-path {
    stroke: $white !important;
}

.mat-tab-label-content {
    color: $black !important;
}

.user-roles {
    .mat-tab-labels {
        border-bottom: 1px solid var(--primary-color);
    }

    .mat-tab-label {
        min-width: auto;
        height: auto;
        padding: 7px 20px;
    }

    .mat-tab-label-content {
        font-weight: 300;
        color: #a7a7a7;
        font-size: 15px;
    }

    .mat-tab-label-active {
        .mat-tab-label-content {
            font-weight: 400;
            color: $black !important;
            opacity: 1;
        }
    }

    .mat-tab-group.mat-primary .mat-ink-bar,
    .mat-tab-nav-bar.mat-primary .mat-ink-bar {
        background-color: var(--primary-color);
    }

    .mat-tab-header-pagination-chevron {
        border-color: $black;
    }

    .mat-tab-body-content {
        padding: 22px 0px 22px;
    }

    .mat-elevation-z4 {
        box-shadow: none !important;
    }
}

.os_over {
    border: 0 !important;
    padding-top: 0 !important;
    padding-left: 0 !important;
    padding-right: calc(1.3em + 0.35rem) !important;
}



.add_user_model {
    border: 1px solid var(--border-light-color);
    background: var(--app-modal-body-color);
    border-radius: 20px;
}

.nsm-content {
    background: transparent !important;
    box-shadow: none !important;
    padding: 0 !important;
    margin: 0 !important;
    margin-top: 40%;
}

.table_heading_width {
    width: 50%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

}

.nsm-dialog-btn-close {
    display: none;
}

// ----------------------------------------------------
// Compilation
// ----------------------------------------------------

.page-header-row {
    padding: 20px 30px;
    border-bottom: 1px solid var(--border-light-color);


    button:disabled,
    button.disabled {
        opacity: .65;
    }

    h2 {
        color: $violet;
        font-size: 26px;
        font-weight: 400;
        padding-bottom: 0;

        @include mobile {
            display: inline-block;
            margin: 0 50px 0 0;
            float: left;

            h2 {
                display: inline-block;
            }

            .heading-btnz {
                float: right;
            }
        }
    }
}



//From_Project_edit


// projects listings
.projects-listing-holder {
    min-height: 50px;
    padding-top: 10px;
}

.create-button {
    border: 1px solid var(--app-button-theme-color);
    @include border-radius(5px);
    color: $white !important;
    background: var(--app-button-theme-color);
    padding: 7px 15px;
    margin-bottom: 20px;
    cursor: pointer;
    display: inline-block;

    @include tablet {
        margin: -1px 0 0;
        display: inline-block;
    }

    @include cell {
        display: inline-block;
    }

    &.active {
        background: var(--app-button-theme-color);
        color: $white;
        border: 1px solid var(--app-button-theme-color);
        @include transitions(0.5s);
    }

    &:before {
        content: "+";
        font-size: 17px;
        margin-right: 5px;
    }
}


.create-project {
    border: 1px solid var(--border-light-color);
    @include border-radius(5px);
    color: var(--app-text-color);
    padding: 7px 15px;
    margin-bottom: 20px;
    cursor: pointer;
    display: inline-block;

    @include tablet {
        margin: -1px 0 0;
        display: inline-block;
    }

    @include cell {
        display: inline-block;
    }


    &:hover,
    &.active {
        background: var(--primary-color);
        color: #fff;
        border: 1px solid var(--primary-color);
        @include transitions(0.5s);
    }


}

.search-holder {

    @include desktop {
        float: right;
        width: 228px;
    }

    .search-field {
        width: 80%;
        box-sizing: border-box;
        padding: 9px 15px;
        border: 1px solid #ebebeb;
        font-size: 14px;
        font-weight: 400;
        color: $black;
        @include placeholder(#555555, 14px);
        border-right: none;
        -webkit-border-top-left-radius: 5px;
        -webkit-border-bottom-left-radius: 5px;
        -moz-border-radius-topleft: 5px;
        -moz-border-radius-bottomleft: 5px;
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
    }

    .search-submit {
        width: 20%;
        box-sizing: border-box;
        padding: 9px 15px;
        border: 1px solid #ebebeb;
        font-size: 14px;
        font-weight: 400;
        color: #555555;
        cursor: pointer;
        background: none;
        -webkit-border-top-right-radius: 5px;
        -webkit-border-bottom-right-radius: 5px;
        -moz-border-radius-topright: 5px;
        -moz-border-radius-bottomright: 5px;
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;

        &:hover {
            background: $violet;
            color: $white;
            border-color: $violet;
            @include transitions(0.5s);
        }
    }
}

button:focus {
    outline: none;
}

ul.table-actions {
    display: block;
    margin: 0px;
    padding: 0px;
    min-width: 135px;
    text-align: center;

    li {
        display: inline-block;
        margin: 0 3px;

        a {
            background: $white;
            padding: 5px 8px;
            text-align: center;
            border: 1px solid #e1e1e1;


            i {
                font-size: 16px;
                color: #8b8b8b;
                display: inline-block;
                padding: 0px;
                @include transitions (0.5s);
                vertical-align: middle;

                i {
                    @include transitions (0.5s);
                }
            }

            &:hover {
                background: $violet;
                border: 1px solid $violet;

                i {
                    color: $white;
                }
            }
        }

    }

    @include xl-desktop {
        min-width: 72px;
    }
}




span {
    &.priority {
        display: inline-block;
        padding: 4px 0px;
        color: $white;
        box-sizing: border-box;
        min-width: 72px;
        text-align: center;
        @include border-radius(4px);

        &.High {
            background: #e92020;
        }

        &.Medium {
            background: #ffc700;
        }

        &.Low {
            background: #8bc34a;
        }
    }

}

span.user-image {
    display: inline-block;
    margin: 0 10px 0 0;
    vertical-align: middle;

    img {
        width: 30px;
        height: 30px;
        @include border-radius(50%);
    }
}



.test-scenerio-table,
.test-case-execution,
.defects-table {
    overflow-x: auto;

    th,
    td {
        a {
            color: #618bd0;
            text-decoration: none;
            font-weight: 500;

            &:hover {
                color: #3372db;

            }
        }
    }
}

.add_user_model {
    width: 100%;
    margin-top: 155px;
    margin-bottom: 80px;

    @media all and (max-width: 800px) {
        margin-top: 10%;
    }
}

.main {
    border-radius: 23px;
}

.close_tab {
    border-bottom: 1px solid var(--border-light-color);
    padding: 20px;

    h6 {
        font-size: 24px;
        font-weight: 500;
        display: inline-block;
        padding: 0;

        margin: 6px 0 0 0;
        color: var(--primary-color);
    }

    h5 {
        font-size: 16px;
        font-weight: bold;
        display: inline-block;
        padding: 0;
        margin: 6px 0 0 0;
        color: var(--primary-color);
    }

}

.h3_close {
    display: inline;
    padding-left: 2rem;
}

.close_icon {
    text-align: right;
    font-size: 22px;
    border: 0 !important;
    background-color: transparent !important;
    float: right;
    color: var(--app-text-color);

    &:hover {
        color: var(--app-text-color);
    }
}

.table_heading {
    background: #f0f0f0;

}

.btn_sort {
    border: 0 !important;
    background-color: transparent !important
}

.round_img {
    border-radius: 50%;
    width: 30px;
    height: 30px;
}

.user_name {
    padding-left: 8%;
    font-size: 12px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.user_email {
    font-size: 12px;
    vertical-align: middle !important;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}


.user_role {
    font-size: 12px;
    vertical-align: middle !important;
    padding-right: 30px !important;
}

.table_r_b {
    background: var(--app-modal-table-body-color);
    vertical-align: middle;
    border-bottom: 1px solid #dee2e6;
}


@media all and (min-width: 770px) and (max-width: 1100px) {

    .page-header-row {
        padding: 10px;
    }

    .create-project {
        font-size: 12px;
        padding: 0.5rem 1rem;
        margin-bottom: 10px;
    }
}

@media all and (min-width: 1024px) and (max-width: 1280px) {
    .create-project {
        font-size: 12px;
        padding: 0.5rem 1rem;
    }

    .i-csv {
        font-size: 12px;
        padding: 0.5rem 1rem;
        margin-right: 0.5rem;
    }
}




@media all and (min-width: 768px) and (max-width: 1024px) {
    .nsm-dialog {
        margin-right: 3%;
    }

    .add_user_model {
        width: 90%;
    }

    .create-project {
        font-size: 12px;
        padding: 0.5rem 1rem;
    }

    .i-csv {
        font-size: 12px;
        padding: 0.5rem 1rem;
        margin-right: 0.5rem;
    }

    .imageHide {
        display: none !important;
    }
}

@media all and (min-width: 480px) and (max-width: 768px) {
    .nsm-dialog {
        margin-right: 3%;
    }

    .imageHide {
        display: none !important;
    }

    .create-project {
        font-size: 12px;
        padding: 0.5rem 1rem;
    }

    .i-csv {
        font-size: 12px;
        padding: 0.5rem 1rem;
        margin-right: 0.5rem;
    }
}

@media all and (max-width: 480px) {
    .round_img {
        display: none;
    }

    .create-project {
        padding: 5px;
    }

    .nsm-dialog {
        margin-right: 3%;
    }
}

//  ------------------------------------------------------------
//  Delete Project PopUp
//  ------------------------------------------------------------
.delete_popup {
    border-radius: 23px;
    border: 1px solid #d8dde1;
    text-align: center;
    max-width: 250px;
    background: #f0f0f0;
    padding-top: 2%;
}


.btn_delete_project {
    width: 4rem;
    height: 2rem;
    background: #dd4216;
    border: 0;
    border-radius: 0.5rem;
    color: white;
    margin-right: 1rem;
    transition: 350ms;

    &:hover {
        background: $violet;
    }
}


.btns-block {
    background: white;
    padding: 5% 0;
    border-radius: 0 0 23px 23px;
}

.padd_lleft {
    padding-left: 2rem !important;
}

//  ------------------------------------------------------------
//  Project-Edit-Styling
//  ------------------------------------------------------------

// .sub-nav-tabs {
//     background: var(--app-button-theme-color);
// }



// ul.sub-nav {
//     margin: 0px;
//     padding: 0px 0 0 0;
//     display: block;

//     li {
//         display: inline-block;

//         a {
//             font-size: 14px;
//             color: $white !important;
//             font-weight: 500;
//             padding: 10px 20px;
//             display: inline-block;
//             @include transitions(0.5s);
//             opacity: .7;

//             &:hover {
//                 color: $white;

//                 opacity: 1;
//             }

//             &.active {
//                 color: $white;
//                 background: var(--app-nav-selected-color);
//                 cursor: pointer;
//                 opacity: 1;
//             }
//         }
//     }
// }


.create-project-form {
    max-width: 1140px;
    margin: 30px auto;
    padding: 0 15px;

    h2 {
        text-align: center;
        font-size: 18px;
        text-transform: uppercase;
        padding: 0 0 50px;
        color: var(--app-text-color);
        font-weight: 500;
    }

}

.input_height {
    height: 38px;
    padding: 6px 12px;
}

.btn_row {
    margin-top: 12%;
}


//  ------------------------------------------------------------
//  Project-Archive-Styling
//  ------------------------------------------------------------


// ------------------------------------------------------------------------
// ---
// ---
// ---             CheckBox Styling
// ---
// ---
// -------------------------------------------------------------------------

.rememberme_style {
    font-size: 0.8rem !important;
    color: var(--primary-color) !important;
    margin-left: 1.4rem;
    margin-bottom: 0;
    padding-bottom: 0 !important;
}

/* Hide the browser's default checkbox */
.remem_style input {
    position: absolute !important;
    opacity: 0 !important;
    cursor: pointer !important;

}

/* Create a custom checkbox */
.checkmark {
    position: absolute;
    top: -2px;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: var(--app-inpur-bg-color);
    border: 1px solid var(--app-input-border-color);
    border-radius: 20%;
}

/* On mouse-over, add a grey background color */
.checkmark:hover {
    background-color: var(--app-inpur-bg-color);
}

/* When the checkbox is checked, add a blue background */
.checkman:checked~.checkmark {
    background-color: var(--primary-color) !important;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
    content: "" !important;
    position: absolute !important;
    display: none !important;
}

/* Show the checkmark when checked */
.checkman:checked~.checkmark:after {
    display: block !important;
}

/* Style the checkmark/indicator */
.checkmark:after {
    left: 6px !important;
    top: px !important;
    width: 9px !important;
    height: 15px !important;
    border: solid white !important;
    border-width: 0 3px 3px 0 !important;
    -webkit-transform: rotate(45deg) !important;
    transform: rotate(45deg) !important;
}

.custom-checkbox .custom-control-input:checked~.custom-control-label::after {
    left: -19px !important;
    top: 3.4px !important;
    width: 8px !important;
    height: 14px !important;
    border: solid var(--app-text-color) !important;
    border-width: 0 3px 3px 0 !important;
    -webkit-transform: rotate(45deg) !important;
    transform: rotate(45deg) !important;
    background-image: none !important;
}


.form-control.is-invalid,
.was-validated .form-control:invalid {
    border: 1px solid #dc3545 !important;
    background: none;
}

// Mat stepper styling


.mat-step-header .mat-step-icon {
    background-color: #3577e4 !important;
    color: #fff;
}

.mat-step-header .mat-step-icon-not-touched {
    background-color: #e6e6e6;
    color: #fff;
}

.mat-stepper-horizontal-line {
    border-top-color: #c3c0d4;
}


.mat-step-header {
    width: 2%;
}

.mat-horizontal-stepper-header-container {
    margin-right: auto;
    margin-left: auto;
    width: 45%;
}

.mat-step-icon .mat-icon {
    display: none;
}

.tox-statusbar {
    display: none !important;
}



.custom-control-label::after {
    position: absolute;
    top: 0 !important;
    left: -1.6rem !important;
    display: block;
    width: 1.5rem;
    height: 1.5rem;
    content: "";
    background: no-repeat 50%/50% 50%;
}

.custom-control-input:checked~.custom-control-label::before {
    background-color: var(--primary-color);
    border: 1px solid var(--primary-color);
    // border-radius: 20%;
}

.custom-checkbox .custom-control-label::before {
    border-radius: 0;
}

.team-listing-holder {
    padding-top: 30px;

    max-width: 100%;
    overflow: hidden;
    overflow-x: scroll;
    overflow-y: auto;
    margin: 0 15px;

    @include tablet {
        margin: 0px;
    }

    @include desktop {
        width: auto;
        overflow: auto;
    }


}

.scroll-act {
    max-width: 100%;
    overflow: hidden;
    overflow-x: scroll;
    overflow-y: auto;
    margin: 0 15px;

    @include tablet {
        margin: 0px;
    }

    @include desktop {
        width: auto;
        overflow: auto;
    }

}


.popup_margin_wrapper {
    padding: 30px 10px !important;
}

.projects-table {
    tbody {
        tr {
            td:first-child {
                padding-left: 30px;
            }
        }
    }
}

.projects-table {
    thead {
        tr {
            th:first-child {
                padding-left: 30px;
            }
        }
    }
}



.tab_action {
    thead {
        tr {
            .e-th {
                width: 10%;
            }

            .email-th {
                width: 35%;
            }

            th:last-child {
                padding-right: 30px;
            }
        }
    }
}

.add_user_model {
    .tab_action {
        table-layout: fixed;
        width: 100%;
        white-space: nowrap;
    }
}



.wid_fix {
    max-width: 395px !important;
    overflow-y: hidden;
    overflow-x: hidden;

    label {
        font-size: 12px;
        line-height: 2;
    }
}

.submit-btnz {
    font-size: 13px;
    background: var(--primary-color);
    color: $white;
    width: 86px !important;
    min-height: 29px;
    @include border-radius(5px);
    cursor: pointer;
    border: none;
    display: block;

    &:hover {
        background: var(--primary-color);
        @include transitions(0.5s);
    }

    &.cancel {
        background: var(--comp-bg-color);
        color: var(--app-text-color);
        border: 1px solid var(--border-light-color);

        &:hover {
            background: var(--primary-color);
            @include transitions(0.5s);
            color: #fff;
        }
    }

    @include tablet {
        display: inline-block;
        text-align: center;
    }
}



.btn-slider-img {
    background-image: url("../src/assets/images/settings-white.png") !important;
    background-repeat: no-repeat !important;
    background-position: left 15px center !important;
    padding-left: 1.2rem !important;
    background-color: #f0f0f0;

    &:hover {
        background-image: url("../src/assets/images/settings-white.png") !important;
        background-repeat: no-repeat !important;
        padding-left: 1.2rem;
    }
}

.dropdown-toggle::after {
    vertical-align: middle;
}

.no-arrow-dropdown {
    background: var(--comp-bg-color);
    padding: 7px 15px;

    em {
        color: var(--app-text-color);
    }

    &:hover,
    &:disabled {
        background: var(--comp-bg-color);
    }

    &::after {
        border: none;
    }
}


.apply_btn_fil {

    transition: 350ms;
    min-height: 38px;
    min-width: 100px;
    font-size: 14px;
    background: var(--primary-color) !important;
    color: white !important;
    border-radius: 3px !important;
    border: none;
    outline: none;

    &:hover {
        border: none;
        outline: none;
        color: #fff;
    }
}

.clearSaveSettings {
    color: var(--primary-color);

    &:hover {
        color: var(--primary-color);
        @include transitions(0.5s);
    }

    .xlear {
        font-size: 18px !important;
        color: var(--primary-color);
        margin-right: 5px;

    }
}




.apply_btn_fil_active {
    border: 1px solid #cecece;
    border-radius: 3px;
    background: var(--primary-color);
    color: white;
}

.imp-download {
    a {
        display: inline-block;
        margin: 0 5px;
    }

}


//   Filter tags
.f_middle {
    text-align: right;
}

.f_t_li {

    background-color: var(--primary-color);
    color: white;
    margin-right: 0.5rem;
    border-radius: 3px;
    padding: 4px 10px;
    font-size: 12px;

    @include xl-desktop {
        margin-top: 0;
    }

    @include mobilePhone {
        margin-top: 5px;
    }


    i {
        font-size: 12px !important;
        cursor: pointer;
    }
}


.filter-tags {
    display: inline-block;
    padding: 10px;

    @include xl-desktop {
        li {
            margin-top: 5px;
        }
    }
}

.clearTagas {
    color: var(--app-text-color);
    text-decoration: underline;
    font-size: 12px;

    &:hover {
        color: var(--app-text-color);
        text-decoration: underline;
    }
}


.pop-tab {
    border-collapse: separate;
    border-spacing: 0.5rem;
    border-top: 1px solid #f1f1f1;
    border-bottom: 1px solid #f1f1f1;
    padding: 0 16px !important;
    margin: 0 -16px !important;
    width: 100%;
}

.up-img {
    height: 50px;
    width: 50px;
    object-fit: cover;
    border: 1px solid var(--border-light-color);
    margin-bottom: 5px;
    font-size: 16px;
}

.up-img-cross {
    position: absolute;
    background: #ff5151;
    color: white;
    border-radius: 50%;
    width: 15px;
    height: 15px;
    text-align: center;
    line-height: 1;
    transition: 350ms;
    top: 39px;
    margin-left: -6px !important;

    &:hover {
        color: #dc3545;

    }
}

.up-img-wrap {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 60px;
    margin-right: 10px;
    background-color: transparent !important;
    color: var(--app-text-color) !important;
}


.add_user_model {
    .tab_action {
        thead {
            tr {
                th:first-child {
                    padding-left: 20px;
                }
            }
        }
    }

    .tab_action {
        tbody {
            tr {

                td {
                    background: var(--app-modal-table-body-color);
                    color: var(--app-text-color);

                    &:first-child {
                        padding-left: 25px;
                    }
                }
            }
        }
    }
}

.add_user_model {
    z-index: 16;
    position: fixed;

    thead th {
        position: sticky;
        top: -1px;
        z-index: 10;
        background: var(--app-modal-table-head-color);
        color: var(--app-text-color);

    }

    .scroll-wrapper {
        min-height: 520px;
    }

}

.col-pd {
    margin-top: 1px;
    margin-bottom: 1px;
}

.mb-15 {
    margin-bottom: 15px;
}


// _____________________________________________details pages-------------------------
.bg_grey {
    background: var(--app-background-gray-alternate-color);
    padding: 30px 56px !important;
}

.bg_white {
    background: var(--app-background-white-alternate-color);
    padding: 35px;
    padding-left: 40px;
    width: 100%;
}

.bb_grey {
    border-bottom: 1px solid #f0f1f3;
    padding: 0;
}

.test_c_heading {
    color: var(--primary-color) !important;
    font-size: 24px !important;
    margin-bottom: 0 !important;
}

.test_c_key {
    color: var(--app-text-color) !important;
}

.test_c_value {
    color: var(--app-text-color) !important;
    vertical-align: top;
    max-width: 350px;
    word-wrap: break-word;

    ul {
        padding: 0 1rem !important;

        li {
            list-style-type: unset !important;
        }
    }
}

.details {
    margin-top: 40px;
}

.test_c_head {
    background: var(--table-bg-color);
}


.test_c_row {
    border-bottom: 20px solid transparent;

    app-attachment-view-new {
        background-color: transparent !important;
    }
}

ol {
    padding-left: 0;
    list-style-position: inside;
}

.tab_comments {
    margin-bottom: 50px;

}

td {
    color: var(--app-text-color);
    display: table-cell;
    vertical-align: top;
}

@media all and (max-width: 1024px) {
    .bg_grey {
        padding: 16px 30px !important;
    }

    .bg_white {
        padding: 18px !important;
    }

    .submit-btn {
        font-size: 12px; //font-size: 15px;
        padding: 8px 8px;
    }

    .tab_comments {
        font-size: 12px;
        background: whitesmoke;
        width: 100%;
        z-index: 5;
    }

    .mat-expansion-panel-body {
        padding: 0 !important;
    }

    .test_c_heading {
        font-size: 14px;
    }

    .mat-expansion-panel-header-title {
        font-size: 17px;
    }

    .hide1000 {
        display: none;
    }
}

@media all and (min-width: 770px) and (max-width: 850px) {
    .bg_white {
        position: absolute;
        z-index: 5;
        background: var(--app-background-white-alternate-color);
        right: 0;
        width: 440px;
        top: 0;
    }
}

// _____________________________________________details pages end-------------------------

// test-case-list
.imp-download {
    float: right;
}


.i-csv {
    background: var(--comp-bg-color);
    border: 1px solid var(--border-light-color);
    @include border-radius(3px);
    color: var(--app-text-color);
    padding: 7px 15px;
    font-weight: 14px;
    font-size: 14px;
    cursor: pointer;

    @include tablet {
        margin: -1px 0 0;
        display: inline-block;
    }

    &:hover {
        background: var(--primary-color);
        color: #fff;
        border: 1px solid var(--primary-color);
        @include transitions(0.5s);
    }
}

.d_csv {
    margin-right: 4rem;
}

.f_left {
    float: left;
    display: inline-flex;
}


.f_right {
    float: right;
    display: inline-flex;
}

.apply_btn {

    border-radius: 3px;
    transition: 350ms;
    width: 90px;
    border: none;
}



.apply_btn:hover {

    border-radius: 3px;
    background: #6dac29;
    color: white;
}

.apply_btn_filter {
    border-radius: 2px;
    transition: 350ms;
    height: 38px;
    width: 75%;
}



.apply_btn_filter:hover {

    border-radius: 2px;
    background: #6dac29;
    color: white;
}

.data_select {
    width: 4% !important;
}


.data_ts {
    width: 12%;
}

.data_desc {

    text-align: left !important;
}

.data_date {
    width: 12%;
}

.data_action {
    width: 12%;
}

.add_user_model_two {
    margin-top: 25%;
}

.select_datatab_tsl {
    padding-right: 0 !important;
}

//  ------------------------------------------------------------
//  Tree Styling
//  ------------------------------------------------------------


.apply_btn_left {
    border: 0;
    border-radius: 2px;
    transition: 350ms;
    background: transparent;
    color: var(--app-text-color);
    padding: 10px 0;
    font-size: 14px;

    &:hover {
        color: var(--app-text-color);
    }
}

.left_dropdown-menu {
    text-align: left;
    background-color: var(--app-dropdown-bg-color);

    li {
        border-bottom: 1px solid var(--border-light-color);

        &:hover {
            background-color: var(--border-light-color);
        }

        &:last-child {
            border: none;
        }

        button {
            display: block;
            white-space: pre-wrap;
            margin: 0 10%;
        }
    }

    margin-top: 0.6rem;
    left: auto !important;
    right: 0px !important;

    -webkit-box-shadow: 0px 0px 13px 0px rgba(0, 0, 0, 0.23);
    box-shadow: 0px 0px 13px 0px rgba(0, 0, 0, 0.23);

    &::before {
        content: "" !important;
        width: 0px !important;
        height: 0px !important;
        position: absolute !important;
        top: -10px !important;
        right: 8px !important;
        border-left: 8px solid transparent !important;
        border-right: 8px solid transparent !important;
        border-bottom: 10px solid var(--app-dropdown-bg-color) !important;
        z-index: 7 !important;
    }

    &.action {
        li {
            padding: 7px 0;

            a {
                text-decoration: none;

                em {
                    margin-right: 5px;
                    margin-left: 15px;
                    width: 16px;
                }
            }
        }

    }
}

.action_dropdown-menu {
    background-color: var(--app-dropdown-bg-color);
    margin-top: 0.4rem;
    -webkit-box-shadow: 0px 0px 13px 0px rgba(0, 0, 0, 0.23);
    box-shadow: 0px 0px 13px 0px rgba(0, 0, 0, 0.23);
    min-width: auto !important;
    padding: 0;


    &::before {
        content: "" !important;
        top: 15px !important;
        right: -8px !important;
        border-left: 8px solid var(--app-dropdown-bg-color) !important;
        border-bottom: 10px solid transparent !important;
        border-top: 10px solid transparent;
        width: 0px !important;
        height: 0px !important;
        position: absolute !important;
        z-index: 7 !important;
    }

    li {
        display: inline-block;
        border-right: 1px solid var(--border-light-color);
        padding: 8px;

        .dropdown-item {
            padding: 0;
            color: var(--app-text-color);

            &:hover {
                background-color: transparent;
                color: var(--app-text-color);
            }
        }

        &:hover {
            background-color: var(--border-light-color);
        }

        &:last-child {
            border-radius: 0 5px 5px 0;
        }

        &:first-child {
            border-radius: 5px 0 0 5px;
        }

        a {
            text-decoration: none;

            em {
                margin: 0 2px;
                width: 15px;
            }
        }
    }
}

.table_dropdown_button {
    font-size: 1rem;
    border: none;
    padding-left: 0;
    background: transparent;

    &.dropdown-toggle::after {
        color: var(--app-text-color);
    }
}


// Dashboard graph styling
.graphHead {
    padding-right: 2rem;
}

.graphDis {
    padding-top: 2rem;
}

.card-heading {
    font-size: 18px;
    color: var(--primary-color);
    font-weight: 500;
}

.defects-by-severity {
    .dx-visibility-change-handler {
        .dxc-chart {
            .dx-export-menu {
                .dx-export-menu-button {
                    transform: translate(60px, -3px) !important;
                }

                .dx-export-menu-list {
                    transform: translate(60px, -3px) !important;
                }

            }
        }
    }

}

.highcharts-credits {
    display: none;
}




// table hover buttons

.projects-table {
    tbody {
        tr:hover {
            box-shadow: inset 1px 0 0 #dadce0, inset -1px 0 0 #dadce0, 0 1px 2px 0 rgba(60, 64, 67, .3), 0 1px 3px 1px rgba(60, 64, 67, .15);
            z-index: 1;
            background: var(--app-datatable-row-hover-color);

            .tab-btns {
                display: flex !important;
            }

        }
    }
}

//   Tree Collapse

.collapse-btn {
    height: fit-content;
    margin-right: 2rem;
    background: var(--primary-color);
    border: none;
    padding: 0px 5px;
    border-radius: 5px;
    transition: 350ms;
    height: 38px;
    width: 38px;
    font-size: 14px;
    color: white;

    &:hover {

        border-radius: 2px;
        background: var(--primary-color);
        color: white;
    }
}

.collapse-btn-img {
    width: 16px;
    height: 16px;

}

.tree-wrapper-closed {
    float: left;
    margin-left: -360px;
    transition: 0.2s ease-in-out;
}

.transition-width {
    transition: 0.3s all !important;

}

.tree_wrapper {
    width: 280px;
    display: inline-block !important;
    height: 80%;

    @include desktop {
        width: 350px !important;
    }


    tree-viewport {
        min-height: 300px !important;
    }

}





.table_wid {
    width: calc(100% - 280px);
    display: inline-block;
    transition: 0.2s all;

    @include desktop {
        width: calc(100% - 350px);
    }
}

.table_wid_close {
    width: 100%;
    display: inline-block;
    transition: 0.2s all;
}

.tree-colapse {
    transition: 0.2s all;
}

.filter {
    color: white;
    float: right;
    background: none;
    border: none;
    padding: 8px 12px;
    font-size: 18px;
}


.severity-filter {
    border-radius: 5px;
    font-size: 14px;
    padding: 8px 7px;
    background: var(--primary-color);

    &:hover,
    &:focus {
        background: var(--primary-color);
    }
}


.filterHide {
    text-indent: -9999px !important;
}

.redAstriek {
    color: #ff002a;
}

// Validation border on create fields
.is-invalid-border {
    border: 1px solid #dc3545 !important;
}


// right filter drop down on listing pages
.right_dropdown-menu {
    min-width: 35rem !important;
    margin-right: 2rem;
    margin-top: 0.5rem;
    left: auto !important;
    right: 0px !important;
}



.red-border {
    border: 1px solid #ff0a0a !important;
}

.c-not {
    cursor: not-allowed;
    color: #a9a9a9;
}

.cal-event-action {
    color: #ffffff !important;

    &:hover {
        color: var(--primary-color) !important;
    }

    .fa {
        font-size: 16px !important;
    }
}

.cdk-drag-preview {

    .userSeverity {
        .cardImg1 {
            margin-right: -4px;
            z-index: 3;
        }

        .cardImg2 {
            margin-right: -4px;
            z-index: 2;
        }

        .cardImg3 {
            z-index: 1;
        }

        .low_sev {
            display: inline-block;
            background: #adcd4f;
            padding: 2px 13px;
            border-radius: 10px;
            font-size: 12px;
            color: white;
            margin-top: 7px;
        }

        .med_sev {
            display: inline-block;
            background: #f09e39;
            padding: 2px 13px;
            border-radius: 10px;
            font-size: 12px;
            color: white;
            margin-top: 7px;
        }

        .high_sev {
            display: inline-block;
            background: #d64853;
            padding: 2px 13px;
            border-radius: 10px;
            font-size: 12px;
            color: white;
            margin-top: 7px;
        }
    }

    .taskInnerBox {
        border-bottom: 1px solid #3577e4;
        margin-bottom: 10px;
    }

    p.taskKey {
        font-size: 11px;
        color: #9a9a9a;
        padding-bottom: 5px;
    }

    span.taskValue {
        color: #373737;
    }

    .card_title {
        font-size: 13px;
        color: #555555;
        display: inline-block;
        width: 75%;
        word-break: break-all;
    }


}

.cal-month-view .cal-event-title:hover {
    text-decoration: none !important;
}

.cal-month-view .cal-event-title {
    cursor: auto !important;
}


//In out tray (Dashboard)

.barsJ {
    text-align: center;
    padding-top: 35px;

}

.bugRepo {
    height: 400px !important;
    overflow-x: auto;
}

.info-txt {
    font-size: 9px;
    padding-left: 15px;
}



.filters-header {

    .form-control:focus {
        box-shadow: none !important;
        background-color: transparent !important;
    }

}

.mat-tab-group.mat-primary .mat-ink-bar,
.mat-tab-nav-bar.mat-primary .mat-ink-bar {
    background-color: var(--primary-color);
}

.caption-csf {
    display: inline-block;
    padding-top: 10px;
}

.hint-relative {
    background: #fff;
}

.introjs-prevbutton.introjs-fullbutton {
    display: none !important;
}

.introjs-tooltip-header {
    display: none !important;
}

.introjs-tooltiptext {
    padding: 0 !important;
}

.introjs-skipbutton {
    display: none !important;
}

.introjs-donebutton {
    display: inline-block !important;
}

.tour-guide-tooltip {
    min-width: 300px !important;
    max-width: 300px !important;
    padding: 20px !important;
}

.tour-guide-init-popup {
    padding: 15px 5px 10px;
}

.tour-guide-init-popup p {
    color: #555555;
}

.tour-guide-init-popup h6 {
    color: var(--primary-color);
    font-weight: bold;
    font-size: 20px;
}

#tour-build-module-requirement-btns {
    display: inline-block;
}

.introjs-helperLayer {
    border: none !important;
    border-radius: 0 !important;
    background-color: rgba(255, 255, 255, 0.4392156862745098) !important;
}



// Buttons

.bill-btn {
    background: var(--primary-color);
    color: white;
    padding: 10px 20px;
    border-radius: 23px;
}

//Fiter Overlay menu on defects | Test Cases | Test Scenerio

.w-onTree-openSideBar {

    @include desktop {
        width: 400px;
        max-width: 400px !important;
    }

    @include xl-desktop {
        width: 650px;
        max-width: 650px !important;
    }

    @include sl-desktop {
        width: 760px;
        max-width: 760px !important;
    }

    @include xxl-desktop {
        width: 960px;
        max-width: 960px !important;
    }

    @include xxxl-desktop {
        width: 1198px;
        max-width: 1198px !important;
    }

    transition: 0.2s all
}

.w-offTree-openSideBar {

    @include desktop {
        width: 600px;
        max-width: 600px !important;
    }

    @include xl-desktop {
        width: 890px;
        max-width: 890px !important;
    }

    @include sl-desktop {
        width: 1000px;
        max-width: 1000px !important;
    }

    @include xxl-desktop {
        width: 1200px;
        max-width: 1200px !important;
    }

    @include xxxl-desktop {
        width: 1438px;
        max-width: 1438px !important;
    }

    transition: 0.2s all
}

.w-offTree-offSideBar {

    @include desktop {
        width: 881px;
        max-width: 881px !important;
    }


    @include sl-desktop {
        width: 1288px;
        max-width: 1288px !important;
    }

    @include xxl-desktop {
        width: 1495px;
        max-width: 1495px !important;
    }

    @include xxxl-desktop {
        width: 1698px;
        max-width: 1698px !important;
    }

    transition: 0.2s all
}

.w-onTree-offSideBar {


    @include desktop {
        width: 530px;
        max-width: 530px !important;
    }

    @include xl-desktop {
        width: 740px;
        max-width: 740px !important;
    }

    @include sl-desktop {
        width: 940px;
        max-width: 940px !important;
    }

    @include xxl-desktop {
        width: 1140px;
        max-width: 1140px !important;
    }

    @include xxxl-desktop {
        width: 1340px;
        max-width: 1340px !important;
    }

    transition: 0.2s all
}

.onScrollWrap {

    height: 300px;
    overflow-y: scroll;
    overflow-x: hidden;

    @include desktop {
        height: unset;
        overflow: unset;
    }
}


.td_underline {
    text-decoration: underline;
}


// material listing
.defect-search-field {
    height: 36px;
    padding-left: 10px;
    margin-left: 15px;
    border: 1px solid #ebebeb;

    @include cell {
        display: block;
        margin-left: auto;
        margin-top: 25px;
        margin-bottom: -15px;
    }
}

.submit-btnz-width {
    width: unset !important;
}

// reports databale responsive ui

.bugs-tha.Defect.ID {
    max-width: 3px !important;
}

.bugs-tha.Severity {
    max-width: 5px;
}

.bugs-tha.Browser {
    max-width: 40px;
}

.bugs-tha.OS {
    max-width: 30px;
}

.bugs-tha.Status {
    max-width: 3px !important;
}

.bugs-tha.desc {
    max-width: 5px;
}

.bugs-tha.er {
    max-width: 40px;
}

.bugs-tha.lisa {
    max-width: 30px;
}

.bugs-th {
    width: 150px !important;
    word-wrap: break-word;
}



.reportTable {
    .mat-expansion-panel-body {

        padding: 0 !important;
    }
}




/*audit trial page*/

.auditTrialPage {

    .mat-paginator-page-size-select {
        .mat-select-value-text {
            color: var(--app-text-color);
        }

        .mat-select-arrow {
            color: var(--app-text-color);
        }
    }



    .mat-paginator-container {
        padding: 0 25px !important;
    }

    .ng-dropdown-panel-items {
        text-align: left !important;
    }


    #start-date {
        width: 100%;
        height: 41px !important;
    }

    #end-date {
        width: 100%;
        height: 41px !important;
    }

    .ng-select-multiple {
        max-width: 100% !important;
    }

}



.auditTrialPage {

    .projects-listing-holder .submit-btn {
        padding: 11px 10px !important;
    }

}


.mat-option {
    background: #fff !important;
    color: #000;

    &:hover {
        background: #eee !important;
        color: #3577e4 !important;
    }
}



.mat-selected {
    background: #eee !important;
    color: #3577e4 !important;
}


.notValidMessage {
    text-align: center;

    figure {
        width: 150px;
        display: inline-block;
        margin-bottom: 30px;

        img {
            width: 100%;
            height: auto;
        }
    }

    strong {
        font-size: 30px;
        color: #d92f2f;
        display: block;
        margin-bottom: 15px;
    }

    p {
        font-size: 16px;
        color: #222222;
        line-height: 25px;


    }
}


.verifiedMessage {
    text-align: center;

    figure {
        width: 180px;
        display: inline-block;
        margin-bottom: 30px;

        img {
            width: 100%;
            height: auto;
        }
    }

    strong {
        font-size: 30px;
        color: #61ab27;
        display: block;
        margin-bottom: 15px;
    }

    p {
        font-size: 18px;
        color: #222222;
        line-height: 25px;

        a {
            font-size: 18px;
            display: inline-block;
            padding: 8px 20px;
            background: #e7e7e7;
            border-radius: 5px;
            color: #3577e4;
            margin-left: 5px;

            &:hover {
                background: #dfdfdf;
                color: #3577e4;
            }
        }
    }
}




.tour-guide-container {
    h1 {
        display: block;
        margin-bottom: 15px;
        font-size: 20px;
        color: white;
        font-weight: 500;
        padding: 0;
    }

    p {
        font-size: 16px;
        color: white;
        line-height: 22px;
        padding: 5px;
    }

    ul,
    ol {
        list-style: none;
        padding-top: 10px;

        li {
            font-size: 14px;
            color: #fff;
            margin-bottom: 5px;
        }
    }

    .tour-animated-gif {
        max-width: 300px;
        display: inline-block;
        margin-bottom: 10px;
        border: 1px solid #eee;
        border-radius: 10px;

        img {
            width: 100%;
            height: auto;
            border-radius: 10px;
        }
    }

    .action-guide-skip-btn {
        position: absolute;
        top: 13px;
        right: 3px;
        font-size: 22px;
        color: #fff;
        line-height: 1px;
        transform: rotate(45deg);
        font-weight: 700;
        cursor: pointer;

        &:hover {
            color: #fff;
        }
    }

}

.introjs-tooltip {
    border-radius: 15px !important;
    background-color: var(--primary-color);
}

.introjs-arrow.left-bottom,
.introjs-arrow.left,
.introjs-arrow.right,
.introjs-arrow.right-bottom {
    border-right-color: var(--primary-color);
}

.introjs-arrow.bottom,
.introjs-arrow.bottom-middle {
    border-top-color: var(--primary-color);
}

.introjs-arrow.top,
.introjs-arrow.top-middle,
.introjs-arrow.top-right {
    border-bottom-color: var(--primary-color);
}

.introjs-nextbutton {
    visibility: hidden;

    &:after {
        content: url(../src/assets/images/tour/next.png);
        visibility: visible;
    }
}

.introjs-prevbutton {
    visibility: hidden;

    &:after {
        content: url(../src/assets/images/tour/prev.png);
        visibility: visible;
    }
}

.introjs-tooltipbuttons {
    text-align: right !important;
    padding: 20px 0 0 0 !important;
    border: none !important;
    display: flex;
    justify-content: flex-end;
    margin-right: 25px;

    &:after {
        display: block;
        clear: both;
        content: "";
    }

    a {

        margin: 0;
        padding: 10px 15px;
        width: 15px;
        background: #fff;
        background-image: none;
        border-radius: 5px;
        font-size: 16px;
        color: #3577e4;
        outline: none;
        border: none;
        text-align: center;
        text-shadow: none !important;

        &:hover,
        &:focus {
            background: #fff;
            color: #3577e4;
        }
    }
}


.tour-guide-skip-btn {
    z-index: 999;
    display: inline-block;
    position: absolute;
    right: 10px;
    top: 18px;
    text-decoration: none;
    border: none;
    background: none;
    cursor: pointer;
    font-size: 26px;
    font-weight: 600;
    color: #fff;
    transform: rotate(45deg);

    i {
        font-size: 18px !important;
    }

    &:hover {
        color: #fff;
    }
}


.introjs-helperLayer {
    display: none;
}


/*tooltip*/

app-tooltip {
    cursor: pointer;
    display: inline-block !important;
}

.tooltipQues {
    display: inline-block;
    background: none;
    width: 17px;
    height: 17px;
    line-height: 17px;
    border-radius: 50%;
    font-size: 11px;
    color: var(--app-text-color);
    text-align: center;
    border: 1px solid var(--app-text-color);
    margin-left: 5px;
    font-weight: 600;
    padding-top: 1px;
    opacity: 0.6;
}


.popover {
    max-width: 270px !important;
    position: absolute !important;
    background: var(--app-tooltip-bg-color);
    border: 1px solid var(--app-tooltip-border-color);
    color: var(--app-text-color);
    font-size: 12px;
    font-weight: 400;
}


.popover-body {
    border-radius: 10px;
    border: 1px solid var(--app-tooltip-border-color);
    background: var(--app-tooltip-bg-color);
    color: var(--app-text-color);
    font-size: 12px;
    font-weight: 400;
    line-height: 14px;
}


.tt-md {
    .popover {
        max-width: 240px !important;
    }
}


.bs-popover-top {

    border-radius: 10px;
}



.bs-popover-bottom>.popover-arrow::after {
    border-bottom-color: var(--app-tooltip-bg-color);
}

.bs-popover-top>.popover-arrow::after {
    border-top-color: var(--app-tooltip-bg-color);
}

.bs-popover-start>.popover-arrow::after {
    border-left-color: var(--app-tooltip-bg-color);
}

.bs-popover-end>.popover-arrow::after {
    border-right-color: var(--app-tooltip-bg-color);
}

.bs-popover-bottom .arrow {
    left: calc(50% - 12px) !important;
}

.bs-popover-top .arrow {
    left: calc(50% - 12px) !important;
}

.bs-popover-right .arrow {
    top: calc(50% - 12px) !important;
}

.bs-popover-top-left .arrow {
    bottom: -3px !important;
    right: -10px !important;
    transform: rotate(324deg) !important;
}

.bs-popover-top-left .arrow:before {
    bottom: -1px;
    border-width: .5rem .5rem 0;
    border-top-color: rgba(0, 0, 0, .25);
}

.bs-popover-top-left .arrow:after {
    border-width: .5rem .5rem 0;
    border-top-color: #fff;
}




.mce-content-body {
    color: var(--app-text-color) !important;
}



.tokenActions {
    text-align: left;

    button {
        border: none;
        outline: none;
        font-size: 16px;
        background: var(--primary-color);
        color: #ffffff;
        padding: 12px 20px;
        border-radius: 5px;
        margin-right: 10px;

        &:hover {
            background: #2562c7;
            color: #fff;
        }
    }
}


.bulkEditBtn {
    min-width: 120px !important;
}


.tooltipSmall {
    .popover {
        max-width: 190px !important;
    }
}


.user-roles .mat-expansion-panel {
    overflow: inherit !important;
}

.btn-outline-primary:hover {
    color: #fff;
    border-color: #2b69d0 !important;
    background: #2b69d0 !important;
}



.mat-slide-toggle {
    .mat-slide-toggle-bar {
        background-color: #dd421673;
    }

    &.mat-checked {
        .mat-slide-toggle-bar {
            background-color: #aef7d3;
        }
    }
}

.is-invalid {
    border: 1px solid #dc3545 !important;
}


.actionDropdownCustom {
    button {
        background: none;

        &:hover {
            background: none;

        }
    }
}


/*charts*/

.permControl {
    .mat-expansion-panel-header {
        &:hover {
            background: #2a6bd7 !important;
            color: #555555 !important;
        }
    }
}

.team-disabled-row td {
    opacity: .5 !important;

}

.defectIdWrap,
.testCaseIdWrap {
    display: block;
    width: 120px;
    word-wrap: break-word;
}

.quickListModal .nsm-dialog {
    max-width: 700px !important;
}



.attachmentsTable {
    border: 1px solid #dee2e6;
    margin-top: 20px;
}

.issuesTableWrap {
    .dataTables_info {
        border-top: none !important;
    }
}

.topSearch {
    margin-top: 20px;
    text-align: right;

    input[type="search"],
    input[type="text"] {
        background: none;
        border-radius: 3px;
        width: 300px !important;
        box-sizing: border-box;
        margin-left: 15px;
        padding: 5px 10px;
        border: 1px solid var(--border-light-color);
        font-size: 14px;
        font-weight: 400;
        color: var(--app-text-color);
        height: 36px;
        outline: none;
        /* border-radius: 5px; */
        @include placeholder(var(--app-text-color), 14px);
        //@include border-radius(5px);

        &:hover,
        &:focus {
            outline: none;
            border: 1px solid var(--app-input-border-focus-color);
        }
    }

}

.hide-element {
    display: none !important;
}

.dbSaveMySetting {
    label {
        padding-top: 3px;
    }
}

.dbClearFilder {
    text-align: right;
    font-size: 14px;
    color: #3577e4;
    margin-top: -10px;
    margin-bottom: 15px;

    i {
        font-size: 16px !important;
        margin-right: 5px;
        color: #3577e4;
    }
}


.domain-admin-content {
    .graphHead {
        padding-right: 0;

        button.filter {
            margin-top: -10px;
        }
    }
}

.azure-info-txt {
    color: #ff002a;
    font-size: 9px;
}



.tourBoxContent {
    margin-bottom: 20px;

    p {
        font-size: 18px;
        color: #292929;

        ul {
            padding: 0;
            margin: 0;

            li {
                font-size: 16px;
                color: #292929;
                margin-bottom: 5px;
            }
        }

    }

    ul {
        padding: 0;
        margin: 0;

        li {
            font-size: 16px;
            color: #292929;
            margin-bottom: 5px;
        }
    }


}


#tour-manage-testcycle {
    background: var(--app-background-color);
}

#tour-summary-container {
    background: var(--app-background-color);
}



app-insight-execution-traceability .mat-expansion-panel-content .mat-expansion-panel-body {
    padding: 20px;
}


.tceJsonBtn {
    background: #6194d8;
    padding: 5px 8px;
    font-size: 10px;
    display: inline-block;
    color: #fff !important;
    border-radius: 3px;

    &:hover {
        background: #3577e4;
    }
}

app-test-case-report .filters-left {

    .form-control:focus {
        color: #fff !important;
    }

}

.datePicFieldWrap {
    small {
        color: var(--app-text-color) !important;
    }

    .form-group {
        margin-bottom: 0 !important;
    }

    input {
        border-radius: 3px;
        height: 42px;
    }

}


.reportCustomSelect,
.testCaseRepostCustomFields {


    .col-lg-6:nth-child(odd) {
        padding-left: 20px !important;
        padding-right: 0 !important;
    }

    .col-lg-6:nth-child(even) {
        padding-left: 15px !important;
        padding-right: 0 !important;
    }


    .ng-select-container {
        height: 42px !important;
    }

    label {
        display: block;
        color: var(--app-text-color);
        font-size: 13px;
        padding: 0 0 5px;
        margin: 0px;
        font-weight: 400;
    }

    .ng-value-container {
        color: #fff !important;
    }




    .form-control {
        background: none !important;
    }

}


/*for defect filter*/

.customMSelect {

    .ng-select .ng-select-container {
        padding-top: 0 !important;
        padding-bottom: 0 !important;
    }

    .ng-value-container {
        padding-top: 0px;
    }

    .ng-placeholder {
        top: 7px !important;
    }
}

/*for defect filter end*/


.attachJsonBtn {
    background: #3577e4;
    display: inline-block;
    height: 38px;
    line-height: 38px;
    padding: 0 8px;
    color: #fff !important;
    font-size: 14px;
    font-weight: 400 !important;
    margin-right: 10px;
    border: 1px solid #cecece;
    border-radius: 2px;

    &:hover {
        background: #2b66c8;
        border: 1px solid #2b66c8;
    }
}

.popupInnerWrap {
    padding: 20px 20px 30px 20px;
}


.user-roles .mat-expansion-panel-body {

    padding: 15px 15px !important;
}



.filters-left {
    padding: 10px;
}

.invalidOutside {
    display: block !important;
}

.curser-pointer {
    cursor: pointer;
}

app-cycle-list {
    .projects-table {
        tr {

            td,
            th {
                .d-inline-block {
                    vertical-align: top !important;
                }
            }
        }
    }
}

.breadcrumbStyleSimple {
    margin: 0 0 20px 0;

    .breadcrumb {
        background: none;
        padding: 0;
        margin-top: 10px;

        li {
            text-transform: capitalize;

            a {
                text-transform: capitalize;
                font-size: 12px;
            }

            margin-bottom: 10px;
        }
    }

    .breadcrumb-item+.breadcrumb-item::before {
        content: ">" !important;
    }
}


tree-viewport {
    overflow-x: auto !important;
    display: flex !important;

}

/*test case tabs*/


.testCaseTabs {

    .mat-tab-header,
    .mat-mdc-tab-header {
        overflow: visible !important;
    }

    .mat-tab-label-container,
    .mat-mdc-tab-label-container {
        overflow: visible !important;
        margin-bottom: 20px;
    }

    .mat-tab-labels,
    .mat-mdc-tab-labels {

        background: var(--app-background-color);
        border-bottom: 2px solid var(--app-details-tab-color);


        .mat-tab-label,
        .mat-mdc-tab {
            overflow: visible !important;
            position: realitive;
            background: var(--app-tab-bg-color);
            opacity: 1;
            height: 45px;
            padding: 0 35px !important;
            margin-right: 6px;

            -webkit-border-top-left-radius: 5px;
            -webkit-border-top-right-radius: 5px;
            -moz-border-radius-topleft: 5px;
            -moz-border-radius-topright: 5px;
            border-top-left-radius: 5px;
            border-top-right-radius: 5px;


            .mat-tab-label-content,
            .mdc-tab__text-label {
                font-weight: 400;
                font-size: 18px;
                color: #fff !important;
            }

            &.mat-tab-label-active,
            &.mdc-tab--active {
                background: var(--app-tab-active-bg-color);
                opacity: 1;

                .mat-tab-label-content,
                .mdc-tab__text-label {
                    color: #fff !important;
                }



            }
        }
    }

    .mat-tab-list,
    .mat-mdc-tab-list {
        .mdc-tab-indicator {
            display: none !important;
        }
    }
}

.reportTablepopup {
    tr {
        td {
            white-space: normal !important;
            word-break: break-all !important;
        }
    }
}



.cyclecheckBox {
    padding: 0 !important;

    .cyclecheckBoxIn {
        padding-left: 25px !important;
        padding-top: 6px !important;
    }

}


.testCaseTabs {
    margin-bottom: 30px;

    .tab_attachments {
        .up-img-wrap {
            position: relative;
            margin-bottom: 10px;

        }

        .up-img-cross {
            top: 0 !important;
            right: 3px !important;
        }
    }
}




.treeWrapperInner {
    height: 100%;
}


app-test-cycle-create,
app-manage-cycle-popup {

    .tree_wrapper {
        padding: 16px;
        border-radius: 8px;
        background: var(--main-bg-color);
        float: none;
        width: 100% !important;
        max-width: 100% !important;
        height: auto !important;
        min-height: 300px;


        @include desktop {
            width: 100% !important;
        }

        tree-viewport {
            min-height: 300px !important;
        }


        .tree {
            margin-top: 10px !important;
            height: 350px !important;

            mat-checkbox {

                .mat-checkbox-layout {
                    margin: -3px 0 0 0 !important;
                }
            }
        }
    }
}


.cycleMoveBtnWrap {
    text-align: center;
    min-height: 50px;
    margin-top: 260px;

    .cycleMoveBtnWrapInner {

        i {
            margin-left: 3px;
            font-size: 12px;
            color: #fff;
        }

        button {
            font-size: 13px;
            padding: 10px 10px;
            background: var(--primary-color);
            color: #fff;
        }
    }
}

.defectVideoAttachment {
    background-color: var(--app-background-color) !important;

    .up-img-wrap {
        position: relative;
        margin-bottom: 10px;

    }

    .up-img-cross {
        top: 0 !important;
        right: 3px !important;
    }
}

/*Accordian Design in form - Start*/



.createTestCasePage,
.editTestCasePage,
.createdefectPage,
.editdefectPage {


    .mat-expansion-panel {
        background-color: var(--app-background-color);

        &.mat-expanded,
        &.mat-expansion-panel-spacing {
            overflow: visible !important;
        }
    }



    .otherFieldsAccordian {
        display: block;
        margin-bottom: 20px;
    }



    .mat-expansion-panel-body {
        background: var(--app-background-color) !important;
        padding: 20px 10px 0 10px !important
    }


    .uploadFileContainer {
        .form-group {
            margin-bottom: 0 !important;
        }

        .image-preview {
            margin-top: 30px !important;
        }
    }

    .stepsForm {
        textarea {
            height: 160px !important;
        }
    }


}

/*Accordian Design in form - End*/

/*bootstrap 5 changes nov-22*/


.dbSaveMySetting {
    label {
        padding-left: 10px;
    }
}





.cal-month-view {
    background-color: transparent !important;
}

.cal-month-view .cal-days {
    border-color: var(--border-light-color) !important;
}

.cal-month-view .cal-day-cell:not(:last-child) {
    border-right-color: var(--border-light-color) !important;
}


.cal-month-view .cal-day-cell.cal-weekend .cal-day-number {
    color: var(--app-text-color) !important;
}

/*angular 14 comment table setting*/

.tab_comments thead th {
    vertical-align: bottom;
    border-bottom: 2px solid #dee2e6;
    border-top: 1px solid #dee2e6;
    padding: 0.75rem;
    background-color: var(--app-details-comment-body-color);
    color: var(--app-text-color);
}

.tab_comments td {
    padding: 0.75rem;
    vertical-align: top;
    border-top: 1px solid #dee2e6;
    background-color: var(--app-details-comment-body-color);
    color: var(--app-text-color);
}

/*Multi ng select new item at top*/
.ng-dropdown-panel-items>div {
    display: flex;
    flex-direction: column;

    [role="option"]:not([id]) {
        order: -1;
    }
}

.custom-control-label::before {
    position: absolute;
    top: .25rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    pointer-events: none;
    content: "";
    background-color: var(--app-input-bg-color) !important;
    border: 1px solid var(--border-dark-color) !important;
}

.custom-control-label::before,
.custom-file-label,
.custom-select {
    transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.custom-control-label {
    position: relative;
    margin-bottom: 0;
    vertical-align: top;
    color: var(--app-text-color);
}

.custom-control-input {
    position: absolute;
    left: 0;
    z-index: -1;
    width: 1rem;
    height: 1.25rem;
    opacity: 0;
}

input[type=checkbox],
input[type=radio] {
    box-sizing: border-box;
    padding: 0;
    background-color: var(--app-checkbox-button-color);
}

.checkbox-disabled::before {
    background-color: #f0f0f0;
    opacity: 0.5
}

.custom-control {
    position: relative;
    display: block;
    min-height: 1.5rem;
    padding-left: 1.5rem;
    -webkit-print-color-adjust: exact;
    print-color-adjust: exact;
}



.listFilterDropdown,
.column-selector {
    z-index: 98 !important;
}

img {
    pointer-events: none;
}

.btn-action {
    font-size: 14px;
    --bs-btn-padding-y: 0.488rem;
}

.group-box-content {
    img {
        width: 40px;
        height: auto;
    }
}

.columns-button {
    border: none;

    &.dropdown-toggle::after {
        color: var(--app-text-color);
    }

    svg {
        width: 25px;
        height: 25px;
        display: inline-block;
        margin-right: 2px;
        margin-bottom: -2px;
    }
}

.clear-filter-button,
.clear-filter-button:hover {
    background-color: red;
}

//toggle ui
.mdc-switch {
    margin-bottom: 7px !important;
}

app-attachment-view {
    background-color: transparent !important;
}

//mat checkbox theme ui
.mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__native-control:enabled:not(:checked):not(:indeterminate):not([data-indeterminate=true])~.mdc-checkbox__background {
    background-color: var(--app-checkbox-button-color) !important;
    border: #adb5bd solid 1px !important;
}

// charts theme styling
.highcharts-background,
.highcharts-button-box {
    fill: var(--graph-bg-color);
}

.highcharts-button-symbol {
    stroke: var(--app-text-color);
}

.highcharts-label.highcharts-data-label,
.highcharts-axis-labels.highcharts-xaxis-labels,
.highcharts-axis-labels.highcharts-yaxis-labels {
    text {
        fill: var(--app-text-color) !important;

        .highcharts-text-outline {
            stroke: var(--app-chart-background-color);
        }
    }
}

.highcharts-legend-item.highcharts-area-series,
.highcharts-legend-item.highcharts-column-series,
.highcharts-legend-item.highcharts-bar-series,
.highcharts-label.highcharts-no-data,
.highcharts-legend-item.highcharts-spline-series,
.highcharts-legend-item.highcharts-line-series,
.highcharts-legend-item.highcharts-pie-series {
    text {
        fill: var(--app-text-color) !important;
    }
}

.highcharts-title,
.highcharts-axis-title {
    fill: var(--app-text-color) !important;
}

.highcharts-axis-line,
.highcharts-tick {
    stroke: var(--app-text-color);
}

.highcharts-grid-line {
    stroke: var(--chart-line-color) !important;
}

.jiraProjects {
    background: var(--app-integeration-edit-table-col-color) !important;
}

.siteAttachments {
    ul {
        li {
            display: inline-block;
            position: relative;
            width: 50px;
            margin-right: 10px;
            margin-bottom: 10px;

            .attaImageWrap {
                cursor: pointer;

                figure {
                    width: 50px;
                    height: 50px;
                    border: 1px solid #ced4da;
                    margin-bottom: 5px;
                    overflow: hidden;
                    background: #fff;

                    img {
                        width: 50px;
                        height: 50px;
                        object-fit: cover;
                    }
                }

                strong {
                    display: block;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    font-size: 12px;
                    font-weight: 400;
                    color: var(--app-text-color);
                }

            }


            .attDelBtn {
                position: absolute;
                left: -5px;
                top: -5px;
                background: #ff5151;
                color: white;
                border-radius: 50%;
                width: 15px;
                height: 15px;
                text-align: center;
                transition: 350ms;
                font-size: 12px !important;

                i {
                    font-size: 12px !important;
                }

            }
        }
    }
}

// common css

.siteScroll {

    &::-webkit-scrollbar {
        width: 6px;
        height: 6px;
        border-radius: 6px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
        background: var(--scrollbar-track-color);
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
        background: var(--scrollbar-thumb-color);
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
        background: var(--scrollbar-thumb-hover-color);
    }

    scrollbar-color: var(--scrollbar-track-color) var(--scrollbar-thumb-color);
    scrollbar-width: thin;
}


.appScroll,
.ng-dropdown-panel-items,
.cssOptions select {

    &::-webkit-scrollbar {
        width: 6px;
        height: 6px;
        border-radius: 6px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
        background: var(--scrollbar-track-color);
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
        background: var(--scrollbar-thumb-color);
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
        background: var(--scrollbar-thumb-hover-color);
    }

    scrollbar-color: var(--scrollbar-track-color) var(--scrollbar-thumb-color);
    scrollbar-width: thin;

}

.detail-back-btn {
    margin-right: 0.5rem;
    background: var(--primary-color);
    padding: 10px 10px;
    border-radius: 2px;
    display: inline-block;
    color: white;

    i {
        color: white;
    }
}


.detailPagesRightSection {
    padding: 20px;
}

.mdc-notched-outline__leading,
.mdc-notched-outline__notch,
.mdc-notched-outline__trailing {
    border-color: var(--border-light-color) !important;

}

.cal-month-view .cal-days .cal-cell-row {
    border-bottom-color: var(--border-light-color) !important;
}

.siteTabs {

    .mat-mdc-tab {
        padding: 0 25px !important;
        height: 40px !important;
        transition: background-color 0.5s;


        .mdc-tab__content {
            .mdc-tab__text-label {
                color: var(--app-text-color) !important;
            }
        }


        &.mdc-tab--active {
            opacity: 1 !important;
            background-color: var(--primary-color);
            border-top-left-radius: 5px;
            border-top-right-radius: 5px;

            .mdc-tab__text-label {
                color: white !important;
                opacity: 1 !important;
            }
        }
    }
}


.node-content-wrapper-focused {
    background-color: var(--app-highlight-bg-dark-color) !important;
}

.import-file {
    position: relative;
    top: 6px;

    em {
        color: var(--primary-color);
        font-size: 28px;
    }
}

.label-color {
    padding: 8px;
    color: #fff;
    font-weight: bold;
    border-radius: 8px;
    background: #ccc;

    &.passed,
    &.completed,
    &.Approved {
        background: #219010;
    }

    &.notrun {
        background: #f1c61c;
    }

    &.failed,
    &.on-hold,
    &.Rejected {
        background: #f11c1e;
    }
}

.custom-label-tag {
    padding: 5px 8px;
    display: inline-block;
    color: #fff !important;
    font-size: 12px;
    font-weight: 400 !important;
    text-decoration: none !important;
    border-radius: 3px;
}

//custom drop down styles
.custom-dropdown {
    display: inline-block;

    .custom-dropdown-toggle {
        padding: 6px 11px;
        border: 1px solid var(--app-dashboard-counter-border-color);
        font-size: 12px !important;
        font-weight: 500;
        line-height: 16px;
        @include transitions(0.3s);

        &.full-radius {
            border-radius: 6px;
        }
    }

    .custom-dropdown-menu {

        display: none;
        position: absolute;
        width: 200px;
        padding: 4px 0px 4px 0px;
        margin-top: 0.5rem;
        border-radius: 6px;
        border: 1px solid var(--app-dashboard-counter-border-color);
        box-shadow: 0px 8px 24px 0px #9DA0A429;
        background: var(--app-background-color) !important;
        text-align: left;
        max-height: 275px;
        overflow-x: hidden;
        overflow-y: auto;
        z-index: 10;

        &.default-position {
            right: -2px;
        }

        .custom-dropdown-option {
            padding: 11px 16px;
            margin: 0;

            a {
                padding: 0 10px;
                display: block;
            }

            em,
            img {
                width: 16px;
                height: 16px;
            }
        }

        .custom-dropdown-option:hover {
            background-color: var(--border-light-color);
        }

        /* width */
        &::-webkit-scrollbar {
            width: 4px;
            border-radius: 6px;
        }

        scrollbar-color: var(--scrollbar-track-color) transparent;
        scrollbar-width: thin;
    }
}

//download template, import and export dropdown position

.download-template-dropdown {

    .custom-dropdown-toggle {
        em.icon {
            font-size: 14px;
        }

        em.arrow {
            margin-left: 5px;
        }
    }

    .custom-dropdown-menu {
        &.download {
            margin-left: 36px;
        }

        &.import {
            margin-left: 5px;
            width: 125px;
        }

        &.export {
            right: 35px;
        }

        width: 160px;
    }
}

// for ng-select to show above popup
.cdk-global-overlay-wrapper,
.cdk-overlay-container {
    z-index: 9999 !important;
}

//integration edit styling
.integration-container {

    .heading {
        font-size: 20px;
        font-weight: 500;
        line-height: 22px;
    }

    .form-container {
        padding: 40px;
        border-right: 1px solid var(--app-drag-drop-popup-border-color);

        .form-group {
            margin-bottom: 25px;
        }

        .control-label {
            font-size: 12px;
            font-weight: 400;
            line-height: 16px;
        }

        .form-control {
            padding: 12px;
            border-radius: 8px;
            border: 1px solid var(--app-drag-drop-popup-border-color)
        }

        .update-button {
            width: 100%;
            padding: 12px 16px;
            border-radius: 8px;
            margin: 0;
            font-size: 14px;
            font-weight: 500;
            line-height: 20px;
            background: #0060FF;
            border: none;
            color: #fff;
        }
    }

    .map-container {
        padding: 40px;

        .empty-list {
            font-size: 14px;
            font-weight: 500;
            line-height: 20px;
            color: var(--app-dashboard-counter-sub-color);
            margin-bottom: 10px;
        }

        .back-button {
            font-size: 12px;
            font-weight: 500;
            line-height: 16px;

            em {
                font-size: 14px;
            }
        }

        .mapProjectBtn {
            padding: 8px 12px;
            border-radius: 6px;
            background: #0060FF;
            color: #fff;
            font-size: 12px;
            font-weight: 500;
            line-height: 16px;
            text-align: center;
            float: right;
        }
    }

    .map-project-container {
        border-radius: 8px;
        border: 1px solid var(--app-drag-drop-popup-border-color);
        padding: 0;
        margin: 5px;

        .projects-container {
            padding: 14px 14px 0 14px;

            .projects {
                padding: 4px 8px 0px 4px;
                border-radius: 999px;
                background: var(--app-map-projects-background-color);
                font-size: 14px;
                font-weight: 500;
                line-height: 20px;
                width: fit-content;
                margin-left: 44px;
                max-width: 92%;

                .project-name {
                    max-width: 100%;
                    overflow: hidden;
                    display: inline-block;
                    line-height: 15px;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                }

                .icon {
                    padding: 0 3px;

                    img {
                        border-radius: 999px;
                        border: 1px solid var(--app-drag-drop-popup-border-color);
                        background: var(--app-background-color);
                        width: 18px;
                        height: 18px;
                        margin-bottom: 6px;
                    }
                }

            }

            .link-icon-container {
                position: absolute;
                width: 26px;
                height: 48px;
                margin-left: 38px;
            }

            .outer-circle {
                border-radius: 50%;
                position: relative;
                width: 48px;
                height: 48px;
                border-left: 3px solid var(--app-drag-drop-popup-border-color);
                bottom: 54px;
                right: 14px;

                .inner-circle {
                    background: var(--app-drag-drop-popup-highlight-color);
                    width: 24px;
                    height: 24px;
                    top: 12px;
                    right: 12px;
                    border-radius: 9999px;
                    border: 1px solid var(--app-drag-drop-popup-border-color);
                    text-align: center;
                    position: relative;

                    em {
                        font-size: 14px;
                        color: #0060FF;
                    }
                }
            }

            .project-detail-row {
                padding: 8px 12px;
                border-bottom: 1px solid var(--app-drag-drop-popup-border-color);

                .heading {
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 20px;
                }

                .value {
                    font-size: 14px;
                    font-weight: 500;
                    line-height: 20px;
                    text-align: end;
                    text-transform: capitalize;
                }
            }

            .project-detail-buttons {
                padding: 16px;

                a {
                    padding: 8px 12px;
                    border-radius: 6px;
                    border: 1px solid var(--app-map-projects-btn-border-color);
                    font-size: 12px;
                    font-weight: 500;
                    line-height: 16px;
                    text-align: center;
                    margin-right: 10px;

                    em {
                        font-size: 14px;
                    }
                }
            }
        }
    }
}

@media all and (min-width: 950px) {
    .map-project-container {
        width: 47%;
    }
}

@media all and (max-width: 949px) {
    .map-project-container {
        width: 100%;
    }
}

.basic-map-popup {

    .max-height-600 {
        max-height: 600px;
        overflow-y: scroll;
        overflow-x: hidden;
    }

    .label-heading {
        font-size: 16px;
        font-weight: 600;
    }

    .jiraProjects {
        padding: 15px;
        background: #f3f3f3;
        line-height: 1.5;
    }

    .fixlink {
        padding: 15px 5px;
        text-align: center;

        .fa-link {
            font-size: 20px !important;
            color: #cac7c7;
        }
    }
}


// new popup wrapper css

.mediumPopupWidth {
    max-width: 720px;

    .add_user_model {
        margin-top: 80px;
    }
}

.ai-PopupWidth {
    max-width: 640px;
}

.label-text p {
    font-size: 12px !important;
    font-weight: 400;
    color: var(--app-text-color);
    opacity: 0.8;
    line-height: 16px;
    text-align: left;
    padding: 0;
    margin: 0 0 2px 0;
}

.popup-wrapper {

    border-radius: 8px;

    .header {
        border-bottom: 1px solid var(--app-drag-drop-popup-border-color);
        padding: 16px 24px 16px 24px;

        h6 {
            color: var(--app-text-color);
            font-size: 20px;
            font-weight: 500;
            line-height: 22px;
            max-width: 600px;
        }

        .close_icon {
            margin-top: 4px;
        }
    }

    .body {
        padding: 24px 15px;
        max-height: 400px;
        overflow-y: auto;

        .bulk-note {
            padding: 12px;
            border-radius: 8px;
            background: var(--app-map-projects-background-color);
            font-size: 12px;
            font-weight: 500;
            line-height: 16px;
            opacity: 0.8;
        }

        .form-group {
            margin-bottom: 20px;

            label {
                font-size: 12px;
                font-weight: 400;
                line-height: 16px;
            }

            .ng-select-container input {
                padding: 0 !important;
            }

            select,
            input {
                padding: 12px !important;
                border-radius: 8px;
                border: 1px solid var(--app-drag-drop-popup-border-color)
            }
        }
    }

    .footer {
        border-top: 1px solid var(--app-drag-drop-popup-border-color);
        padding: 16px 24px 16px 24px;

        .buttons-container {
            display: flex;
            flex-direction: row;
            align-content: center;
            justify-content: space-around;
            align-items: center;


            button,
            a {
                padding: 12px 16px 12px 16px;
                border-radius: 8px;
                margin: 0 !important;
                font-size: 14px;
                font-weight: 500;
                line-height: 20px;
                text-align: center;
                min-width: 200px;
                cursor: pointer;

                &.submit-btn {
                    background: #0060FF;
                }

                &.cancel {
                    background: var(--app-drag-drop-popup-disable-button);
                }

                &.cancel-btn {
                    background: transparent;
                    color: var(--app-text-color);
                    border: 1px solid var(--app-drag-drop-popup-cancel-btn-color);
                    border-radius: 8px;
                }

                &.large-btn {
                    min-width: 300px;
                }
            }
        }
    }
}

#toastr-container>div.toastr-custom {
    padding: 0 !important;

    .icon {
        display: flex;
        justify-content: center;
        font-size: 25px;
        align-items: center;
    }

    .title {
        font-size: 16px;
        font-weight: 700;
        line-height: 22px;
        margin-bottom: 5px;
    }
}


.sub-nav-tabs-new {
    padding: 0px 40px;
    border-bottom: 1px solid var(--app-drag-drop-popup-border-color);
    margin-bottom: 15px;

    .sub-nav-new {

        li {
            display: inline-block;
            margin-right: 15px;
            padding: 12px 0px;

            &:has(a.active) {
                border-bottom: 2px solid #0355DC;
            }

            a {
                font-size: 14px !important;
                font-weight: 500;
                line-height: 20px;
            }

            .custom-dropdown {
                .custom-dropdown-toggle {
                    padding: 0;
                    border: none;
                }

                .custom-dropdown-option {
                    &:hover {
                        background: none
                    }

                    a {
                        &.active::after {
                            content: "\EB7B";
                            font-family: 'remixicon';
                            color: #0355DC;
                            float: right;
                        }
                    }
                }

            }
        }
    }
}

.executive-summary-button {
    padding: 8px 12px;
    border-radius: 6px;
    background: #5A00E0;
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    text-align: center;
    color: #ffff;
    white-space: nowrap;
}


// tinymce image popup styling

.tox-dialog {

    border: 1px solid var(--border-light-color) !important;
    background: var(--app-modal-body-color) !important;
    border-radius: 8px !important;

    .tox-dialog__header {
        border-bottom: 1px solid var(--app-drag-drop-popup-border-color) !important;
        padding: 16px 24px 16px 24px !important;
        background-color: transparent !important;

        .tox-dialog__title {
            color: var(--app-text-color) !important;
            font-size: 20px !important;
            font-weight: 500 !important;
            line-height: 22px !important;
            max-width: 600px !important;
        }

        button {
            border: 0 !important;
            background-color: transparent !important;
            color: var(--app-text-color) !important;
        
            &:hover {
                color: var(--app-text-color) !important;
            }
        }
    }

    .tox-dialog__body {
        padding: 24px 15px !important;
        max-height: 400px;
        overflow-y: auto;

        .tox-tab {
            color: var(--app-text-color) !important;
        }

        button {
            border: 0 !important;
            background-color: transparent !important;
            color: var(--app-text-color) !important;
            svg {
                fill: var(--app-text-color) !important;
            }
        
            &:hover {
                color: var(--app-text-color) !important;
            }
        }

        .tox-form__group {
            margin-bottom: 20px;

            .tox-label {
                font-size: 12px;
                font-weight: 400;
                line-height: 16px;
                color: var(--app-text-color);
            }

            select,
            input {
                padding: 12px !important;
                border-radius: 8px;
                border: 1px solid var(--app-drag-drop-popup-border-color);
                background: var(--app-modal-body-color) !important;
                color: var(--app-text-color) !important;
            }

            option {
                background: var(--app-modal-body-color) !important;
                color: var(--app-text-color) !important;
            }
        }
    }

    .tox-dialog__footer {
        border-top: 1px solid var(--app-drag-drop-popup-border-color) !important;
        padding: 16px 24px 16px 24px !important;
        background-color: transparent !important;

        .tox-dialog__footer-end {
            display: flex;
            flex-direction: row;
            align-content: center;
            justify-content: space-around;
            align-items: center;
            width: 100%;


            button {
                padding: 12px 16px 12px 16px;
                border-radius: 8px;
                margin: 0 !important;
                font-size: 14px;
                font-weight: 500;
                line-height: 20px;
                text-align: center;
                min-width: 200px;
                cursor: pointer;

                &.tox-button {
                    background: #0060FF;
                }


                &.tox-button--secondary {
                    background: transparent;
                    color: var(--app-text-color);
                    border: 1px solid var(--app-drag-drop-popup-cancel-btn-color);
                    border-radius: 8px;
                }
            }
        }
    }
}