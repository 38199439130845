// report common csss

.report-section {
  .page-header-row {
    padding: 0px 20px 20px 15px;
    text-align: center;

    // @include desktop {
    //   padding: 35px 15px 0px 20px;
    // }

    h2 {
      color: var(--primary-color);
      font-size: 26px;
      font-weight: 400;
      padding: 0 0 30px;
    }
  }

  .create-project {
    border: 1px solid #e5e5e5;
    @include border-radius(20px);
    color: $orangeRoughy;
    background: white;
    padding: 7px 25px;
    font-weight: 14px;
    margin-bottom: 20px;
    cursor: pointer;

    &:hover {
      background: $violet;
      color: $white;
      border: 1px solid $violet;
      @include transitions(0.5s);
    }
  }

  ul.import-btns {
    display: block;
    text-align: center;
    padding: 0px;
    margin: 0px;

    li {
      padding: 0 10px 20px;

      a {
        border: 1px solid #e5e5e5;
        @include border-radius(20px);
        color: $violet;
        background: white;
        padding: 7px 25px;
        font-weight: 14px;
        display: inline-block;

        &:hover {
          background: $violet;
          color: $white;
          border: 1px solid $violet;
          @include transitions(0.5s);
        }
      }
    }
  }

  ul.documents {
    display: block;
    text-align: center;
    padding: 0px;
    margin: 0px;

    li {
      display: inline-block;
      padding: 0 7px;
    }
  }

  @media only screen and (min-width: 470px) {
    .page-header-left {
      h2 {
        float: left;
      }

      .create-project {
        float: right;
      }
    }

    ul.import-btns {
      text-align: left;

      li {
        display: inline-block;
      }
    }
  }

  @media only screen and (min-width: 570px) {
    ul.import-btns {
      float: left;

      li {
        padding-left: 5px;
        padding-right: 5px;

        a {
          padding: 7px 15px;
        }
      }
    }

    ul.documents {
      float: right;
    }
  }

  @media only screen and (min-width: 876px) {
    ul.import-btns {
      float: left;

      li {
        padding-left: 10px;
        padding-right: 10px;

        a {
          padding: 7px 25px;
        }
      }
    }

    ul.documents {
      float: right;
    }
  }

  @media only screen and (min-width: 1340px) {
    .page-header-left {
      float: left;
      width: 50%;

      .create-project {
        float: left;
        margin-left: 30px;
      }
    }

    .page-header-right {
      float: right;
      width: 50%;
    }
  }

  .page-header-left {
    float: left;
    width: 50%;

    .create-project {
      float: left;
      margin-left: 30px;
    }
  }

  .page-header-right {
    float: right;
    width: 50%;
  }

  .filters-header {
    .card-header {
      background: #373737;
      padding: 13px 15px;

      cursor: pointer;
      border: 1px solid #e1e1e1;
      position: relative;
      @include border-radius(0px);

      .panel-title {
        .accordion-toggle {
          .btn {
            padding: 0px 0 0 30px;

            font-size: 17px;
            color: $white;
            background: url(../../../../src/assets/images/reports-filter.png) 0px 2px no-repeat;
          }
        }
      }

      &::after {
        content: "+";
        position: absolute;
        top: 12px;
        right: 20px;
        width: 30px;
        height: 30px;
        background: $white;
        color: #4b4b4b;
        font-size: 18px;

        box-sizing: border-box;
        text-align: center;
        padding-top: 4px;
      }

      &:hover {
        background: #373737;

        .panel-title {
          .accordion-toggle {
            .btn {
              color: $white;
              text-decoration: none;

              &:hover {
                text-decoration: none;
              }
            }
          }
        }

        &::after {
          content: "+";
          position: absolute;
          top: 12px;
          right: 20px;
          width: 30px;
          height: 30px;
          background: #9b9b9b;
          color: #4b4b4b;
          font-size: 23px;

          box-sizing: border-box;
          text-align: center;
          padding-top: 0px;
        }
      }
    }

    .card {
      background: none;
      border: none;
      border-radius: none !important;
      margin-bottom: 12px;
    }

    .panel-open {
      .card-header {
        background: #373737;
        border: none;

        .panel-title {
          .accordion-toggle {
            .btn {
              color: $white;
            }
          }
        }

        &::after {
          content: "-";
          position: absolute;
          top: 12px;
          right: 20px;
          width: 30px;
          height: 30px;
          background: #9b9b9b;
          color: #4b4b4b;
          font-size: 23px;
          box-sizing: border-box;
          text-align: center;
          padding-top: 0px;
        }
      }

      .panel-title {
        .accordion-toggle {
          .btn {
            color: $white;
            text-decoration: none;

            &:hover {
              text-decoration: none;
            }
          }
        }
      }
    }

    .card-header:first-child {
      border-radius: calc(0rem - 0px) calc(0rem - 0px) 0 0 !important;
    }

    .card-body {
      padding: 0px;
    }

    .accordion-content {
      background: #373737;
      padding: 20px 10px 20px;
      border: 0px solid #e1e1e1;
      border-top: 1px solid #4b4b4b;

      @include desktop {
        padding: 20px 30px 20px;
      }
    }

    // .mat-expansion-panel-body {
    //   background: var(--app-reports-panel-body-color);
    // }

    // .mat-expansion-panel-header {
    //   background: #2a6bd7 url(../../../../src/assets/images/reports-filter.png) 19px 8px no-repeat !important;
    //   padding-left: 45px !important;
    // }
  }

  // .form-row {
  //   padding: 0 10px 30px;
  //   width: 100%;
  //   box-sizing: border-box;

  //   &.date-row {
  //     float: none;
  //     width: 100%;
  //     box-sizing: border-box;

  //     @media only screen and (min-width: 1290px) {
  //       float: left;
  //       width: 50%;
  //     }
  //   }

  //   label {
  //     display: block;
  //     color: #ebebeb;
  //     font-size: 13px;
  //     padding: 0 0 5px;
  //     margin: 0px;
  //     font-weight: 400;

  //     span {
  //       color: $orangeRoughy;
  //     }
  //   }
  // }

  // .blackOptions {
  //   background: url(../../../../src/assets/images/dropdown-arrow-grey.png) right center no-repeat var(--app-reports-form-control-color);
  // }

  // .cssOptions {
  //   box-sizing: border-box;
  //   width: 100%;
  //   border: 1px solid #8c8c8c;
  //   color: #ffffff;
  //   @include border-radius(3px);
  //   position: relative;
  //   min-width: 150px;

  //   select {
  //     background: transparent;
  //     width: 100%;

  //     padding: 7px 15px;
  //     font-size: 14px;
  //     color: #ffffff;
  //     border: 0;
  //     border-radius: 0;
  //     -webkit-appearance: none;
  //     -moz-appearance: none;
  //     appearance: none;

  //     option {
  //       color: var(--app-text-color);
  //       background: var(--app-reports-select-option-color);
  //       font-size: 14px;

  //       &:first-child {
  //         display: none;
  //       }
  //     }

  //     &:focus {
  //       outline: none;
  //     }

  //     &.disabled {
  //       cursor: not-allowed;
  //     }
  //   }

  //   .menu-icons {
  //     position: absolute;
  //     top: 10px;
  //     left: 7px;

  //     & i {
  //       color: #666666;
  //       font-size: 17px;
  //     }
  //   }

  //   .input-date {
  //     min-height: 40px;
  //   }
  // }



  .date-input {
    background: none;
    box-sizing: border-box;
    width: 100%;
    border: 0px solid #8c8c8c;
    color: #ffffff;
    padding: 9px 5px 5px 27px;
    position: relative;
  }

  .mat-checkbox {
    label {
      display: inline-block;

      span {
        &.mat-checkbox-label {
          color: #ebebeb;
          font-size: 13px;
        }
      }
    }
  }

  .mat-checkbox-frame {
    border-color: #8c8c8c;
  }

  .button-row {
    border-top: 1px solid var(--border-dark-color);
    padding: 20px 0 0px;
    text-align: right;

    a {
      display: inline-block;
      padding: 10px 25px;
      @include border-radius(9px);
      background: #2b69d0;
      color: $white;
      font-size: 14px;

      font-weight: 500;

      &:hover {
        background: $violet;
        @include transitions(0.5s);
      }
    }
  }

  .table-holder {
    width: 1000px;

    @include desktop {
      width: auto;
    }
  }

  .projects-table {
    tr {
      &:nth-child(odd) {
        background: #f0f0f0;
      }

      &:nth-child(even) {
        background: #e4e4e4;
      }

      td {
        text-align: left;
        vertical-align: middle;
        padding: 15px 15px 15px 20px;
        font-size: 14px;
        color: #555555;
        font-weight: 400;

        a {
          color: #555555;

          &:hover {
            color: $orangeRoughy;
          }
        }

        span {
          &.user-image {
            display: inline-block;
            margin-right: 20px;

            img {
              width: 46px;
              height: 46px;
              @include border-radius(50%);
            }
          }
        }

        &.heading {
          border-bottom: 1px solid #d9d9df;
          font-size: 14px;
          font-weight: 500;
          color: #2e2e2e;
          padding: 20px 15px 20px 20px;
        }
      }
    }
  }

  ul.table-actions {
    display: block;
    margin: 0px;
    padding: 0px;

    li {
      display: inline-block;
      padding: 0 5px;

      a {
        background: $white;
        padding: 2px 6px;
        text-align: center;
        border: 1px solid #e1e1e1;

        i {
          font-size: 14px;
          color: #8b8b8b;
          display: inline-block;
          padding: 0px;
          @include transitions(0.5s);

          i {
            @include transitions(0.5s);
          }
        }

        &:hover {
          background: $violet;
          border: 1px solid $violet;

          i {
            color: $white;
          }
        }
      }
    }
  }

  table.dataTable {
    thead {
      background: #f0f0f0;
    }
  }

  table.dataTable thead th,
  table.dataTable tfoot th {

    font-size: 14px;
    font-weight: 500;
    text-transform: uppercase;
    color: #555555;
  }

  // =======to capital the table header ==========
  .tableHeaderCap {

    font-size: 14px;
    font-weight: 500;
    text-transform: uppercase;
    color: #555555;
  }

  // =======================================
  table.dataTable thead th,
  table.dataTable thead td {
    border-bottom: 1px solid #e4e4e4;
    padding: 14px 10px;
    border-top: 1px solid #e4e4e4;
  }

  table.dataTable tbody th,
  table.dataTable tbody td {

    font-size: 14px;
    font-weight: 500;
    padding: 15px 10px;
    color: #555555;
  }

  table.dataTable.display tbody tr.odd>.sorting_1,
  table.dataTable.order-column.stripe tbody tr.odd>.sorting_1 {
    background: none;
  }

  table.dataTable.display tbody tr.even>.sorting_1,
  table.dataTable.order-column.stripe tbody tr.even>.sorting_1 {
    background: none;
  }

  table.dataTable.display tbody tr.even>.sorting_1,
  table.dataTable.order-column.stripe tbody tr.even>.sorting_1 {
    background: none;
  }

  table.dataTable.stripe tbody tr.odd,
  table.dataTable.display tbody tr.odd {
    background: #f9f9f9;
  }

  table.dataTable.no-footer {
    border-bottom: 1px solid #e4e4e4;
  }

  .dataTables_info {
    border-top: 1px solid #e4e4e4;

    font-size: 12px;
    font-weight: 500;
    padding: 15px 10px;
    color: #555555;
  }

  .dataTables_filter {
    label {

      font-size: 12px;
      font-weight: 500;
      color: #555555;
    }
  }

  .table-holder {
    div.dataTables_length {
      display: none !important;
    }
  }

  span {
    &.priority {
      display: inline-block;
      padding: 4px 0px;
      color: $white;
      box-sizing: border-box;
      min-width: 72px;
      text-align: center;
      @include border-radius(4px);

      &.High {
        background: #e92020;
      }

      &.Medium {
        background: #ffc700;
      }

      &.Low {
        background: #8bc34a;
      }
    }
  }

  table.dataTable thead .sorting_asc {
    background: none;
    position: relative;

    &::after {
      content: "";
      width: 9px;
      height: 13px;
      background: url(../../../../src/assets/images/table-arrows-active.png) 0px 0px no-repeat;
      z-index: 10;
      position: absolute;
      margin-left: 10px;
      margin-top: 3px;
    }
  }

  table.dataTable thead .sorting_desc {
    background: none;
    position: relative;

    &::after {
      content: "";
      width: 9px;
      height: 13px;
      background: url(../../../../src/assets/images/table-arrows-des.png) 0px 0px no-repeat;
      z-index: 10;
      position: absolute;
      margin-left: 10px;
      margin-top: 3px;
    }
  }

  table.dataTable thead .sorting {
    background: none;
    position: relative;

    &::after {
      content: "";
      width: 9px;
      height: 13px;
      background: url(../../../../src/assets/images/table-arrows.png) 0px 0px no-repeat;
      z-index: 10;
      position: absolute;
      margin-left: 10px;
      margin-top: 3px;
    }
  }

  .dataTables_wrapper {
    margin-top: 20px;
  }

  .dataTables_filter {
    @include tablet {
      width: 567px;
    }

    label {
      font-size: 14px;
      font-weight: 500;
      color: #555555;
    }

    input[type="search"] {
      width: 75%;
      box-sizing: border-box;
      padding: 5px 10px;
      border: 1px solid #ebebeb;
      font-size: 14px;
      font-weight: 400;
      color: $black;
      @include placeholder(#555555, 14px);
      @include border-radius(5px);
    }
  }

  // .dataTables_wrapper .dataTables_paginate {
  //   padding-top: 25px;
  // }

  // .dataTables_paginate {
  //   .paginate_button {
  //      
  //     font-size: 14px;
  //     color: #555555;
  //     padding: 10px 15px;
  //     text-align: center;
  //     cursor: pointer;
  //     background: none;
  //     border: 1px solid #ebebeb;
  //   }
  // }

  // .dataTables_wrapper .dataTables_paginate .paginate_button.current,
  // .dataTables_wrapper .dataTables_paginate .paginate_button.current:hover {
  //   background: #f9f9f9;
  //   border: 1px solid #ebebeb;

  //   font-size: 13px;
  //   color: #555555;
  //   text-align: center;
  //   cursor: pointer;
  //   margin: 0px;
  //   border-right: none;
  //   padding: 10px 15px;
  // }

  // .dataTables_wrapper .dataTables_paginate .paginate_button.disabled,
  // .dataTables_wrapper .dataTables_paginate .paginate_button.disabled:hover,
  // .dataTables_wrapper .dataTables_paginate .paginate_button.disabled:active {
  //   font-size: 13px;
  //   color: #555555;
  //   text-align: center;
  //   cursor: pointer;
  //   background: none;
  //   border: 1px solid #ebebeb;
  //   margin: 0px;
  //   border-right: none;
  //   padding: 10px 15px;
  // }

  // .paginate_button.last {
  //   border-right: 1px solid #ebebeb !important;
  // }

  // .dataTables_wrapper .dataTables_paginate .paginate_button {
  //   font-size: 14px;
  //   color: #555555;
  //   padding: 10px 15px;
  //   text-align: center;
  //   cursor: pointer;
  //   background: none;
  //   border: 1px solid #ebebeb;
  //   margin: 0px;
  //   border-right: none;
  // }

  // .dataTables_wrapper .dataTables_paginate .paginate_button:hover {
  //   font-size: 14px;
  //   color: #555555 !important;
  //   padding: 10px 15px;
  //   text-align: center;
  //   cursor: pointer;
  //   background: #f9f9f9;
  //   border: 1px solid #ebebeb;
  //   margin: 0px;
  //   border-right: none;
  // }

  // .dataTables_wrapper .dataTables_info {

  //   font-size: 14px;
  //   color: #b5b5b5;
  //   font-weight: 400;
  // }

  span.user-image {
    display: inline-block;
    margin: 0 10px 0 0;

    img {
      width: 40px;
      height: 40px;
      @include border-radius(50%);
    }
  }

  // .table.dataTable tbody th.first-col,
  // table.dataTable tbody td.first-col {
  //   padding-left: 30px;
  // }

  // table.dataTable thead th.first-col,
  // table.dataTable thead td.first-col {
  //   padding-left: 30px;
  // }

  // .table-visual {
  //   margin: 20px 0 0;
  // }

  // .dataTables_wrapper .dataTables_filter {
  //   padding-right: 15px;
  //   padding-bottom: 5px;
  // }

  // table.dataTable thead th.center,
  // table.dataTable thead td.center {
  //   text-align: center;
  //   align-content: center;
  // }

  // table.dataTable tbody th.center,
  // table.dataTable tbody td.center {
  //   text-align: center;
  //   align-content: center;
  // }

  // .accordion-detail {
  //   &.dark-bg {
  //     background: var(--app-reports-panel-body-color);
  //     padding-bottom: 10px;
  //   }
  // }

  .mat-calendar-body-selected {
    background-color: #2b69d0 !important;
  }

  .mat-datepicker-toggle-active {
    color: #2b69d0 !important;
  }

  .mat-icon-button {
    outline: none !important;
  }

  .input-date {
    width: 100%;
    box-sizing: border-box;
    background: none;
    border: none;
    color: $white;
    padding: 7px 12px;
  }

  .cssOptions {
    .mat-icon-button {
      position: absolute;
      top: 0px;
      left: 0px;
      width: 100%;
      height: 100%;
      @include border-radius(0px);
    }

    .mat-button-wrapper {
      visibility: hidden;
      opacity: 0;
      display: none !important;
    }

    .datepicker {
      padding-left: 0;
    }
  }

  .table-holder {
    min-height: 200px;
  }

  .data:first-child {
    white-space: pre-wrap;
    padding-left: 30px;
  }

  .bugs-th:first-child {
    padding-left: 30px;
  }

  :host.data {
    width: 20%;
  }

  // .persa {
  //   tr:nth-child(odd) {
  //     background: var(--app-datatable-odd-row-color);
  //   }

  //   tr:nth-child(even) {
  //     background: var(--app-datatable-even-row-color);
  //   }
  // }

  // input.to_datez,
  // input.from_datez,
  // input.start_datez,
  // input.from_tc_createdz,
  // input.to_tc_createdz,
  // input.from_tc_executedz,
  // input.to_tc_executedz {
  //   background: url(../../images/dropdown-arrow-grey.png) right center no-repeat var(--app-reports-form-control-color);
  //   color: #fff;
  //   border: 0;

  //   &::placeholder {
  //     color: #ffffff;
  //   }
  // }

  // :host .custom-control-label {
  //   line-height: 2;
  //   width: 90%;
  // }

  // .reports-table-ui {
  //   table-layout: fixed;
  // }

  // td {
  //   word-break: unset;
  // }

  .mat-expansion-panel {
    overflow: inherit !important;
  }
}

//reports popup table
.testCaseTitle {
  text-align: left !important;
  font-size: 16px;
  color: #3577e4;
}

.test_c_row2 {
  tr {
    td {
      text-align: left !important;
    }
  }
}

.testCaseTable {
  tr {
    th {
      text-align: center;
    }
  }
}

.sub-testcase-poup-wrap {
  div h6 {
    text-align: left !important;
  }
}

:host .close_tab h6 {
  display: block !important;
}

.popup_margin_wrapper {
  text-align: left !important;
}

.reportTable {

  .mat-expansion-panel-body {
    padding: 0 !important;
  }
}

.detailStepTable {
  td {
    word-break: break-all;
  }

  td,
  th {
    background-color: var(--app-background-color);
    color: var(--app-text-color);
  }
}

:host .nsm-dialog {
  max-width: 650px !important;
}

.repCustomTable {
  max-width: 430px;
  overflow-x: auto;

  th,
  td {
    font-size: 13px;
  }
}

.popup_margin_wrapper {
  padding-bottom: 0 !important;
}



////



/*result table*/

.reportResultTable {
  padding: 0 15px;

  .reportTableInner {
    /*        border: 1px solid #eee;*/
    max-height: 400px;
    overflow: auto;
  }

  .mdc-text-field--filled:not(.mdc-text-field--disabled) {
    background-color: transparent !important;
  }

  // .mat-table,
  // .mat-mdc-table {
  //   border: 1px solid #eee;
  //   max-height: 300px;
  //   overflow: auto;

  //   tr {

  //     th,
  //     td {
  //       vertical-align: middle !important;
  //       padding: 10px 15px !important;
  //     }
  //   }
  // }
}

/*schedule Report*/

.schedule-create-form {
  background: var(--app-report-schedule-create-body-color);
  padding-bottom: 30px !important;
  width: 0;
  transition: 300ms;
  overflow: hidden;

  h2 {
    font-size: 18px;
    border-bottom: 1px solid #eaeaea;
    padding: 16px 0;
    padding-left: 0px;
    padding-left: 28px;
    background: var(--app-report-schedule-create-body-color);
    color: $violet;
  }

  .scheduleCreateFormInner {
    padding: 10px 15px;

    .scehdule-form {
      padding: 0;
    }
  }

  .form-group {
    margin-bottom: 15px !important;
  }
}


// New Css



.reportFormWrapper {
  background: var(--comp-bg-color);
  border: 1px solid var(--comp-border-color);
  padding: 15px;
  border-radius: 3px;
  ;
}


.filterSecLeft {
  padding: 15px 25px;
  height: 100%;


  .filterFormLeft {}

  .filterFormRight {}


  .form-row {
    margin-bottom: 15px;

    label {
      color: var(--app-text-color) !important;
    }
  }

}



.filterSecRight {
  padding: 20px;

  .extended-search {


    h3 {

      font-size: 18px;
      color: var(--primary-color);
      padding: 0 0 15px;
    }

    .extened-list {
      display: block;
      margin: 0px;
      padding: 0px;

      li {
        display: inline-block;
        padding: 0 15px 10px 0 !important;
        width: 30%;
        box-sizing: border-box;
        position: relative;

        label {
          font-size: 12px;
          color: var(--app-text-color);
        }


        .form-check {
          margin-left: 0 !important;

          .form-check-input {
            margin-left: -1.5em !important;
            margin-top: 2px !important;
          }
        }

        & .mat-checkbox-inner-container {
          width: 17px;
          height: 17px;
          margin-top: -2px !important;
        }

        & .mat-checkbox-background {
          background-color: #373737;
        }
      }
    }
  }




}