@import "reset.scss";
@import "responsive.scss";



// Login Page, Forgot Pass page
// -------------------------------------------------------


.loginPageLeft {
    background-color: #0355DC;
    min-height: calc(100vh - 20px);
    border-radius: 16px;
    margin: 10px 0;
    padding: 50px 100px;

    @include lg-down {
        padding: 30px 20px;
        min-height: 100px !important;
    }


    .loginPageLogo {
        max-width: 130px;
        margin-bottom: 60px;

        @include lg-down {
            margin-bottom: 30px;
        }

        img {
            width: 100%;
            height: auto;
        }
    }

    .loginPageFeatures {
        margin-bottom: 180px;

        @include lg-down {
            display: none;
        }

        ul {
            li {
                border: 1px solid rgba(255, 255, 255, 0.3);
                padding: 5px 20px;
                color: #fff;
                font: 14px;
                display: inline-block;
                margin-right: 10px;
                margin-bottom: 10px;
                border-radius: 50px;
            }
        }

        .player-1 {
            position: relative;
            top: 15px;
            left: 150px;
        }

        .player-2 {
            position: relative;
            top: 55px;
            left: 200px;
        }

        .player-3 {
            position: relative;
            top: -21px;
            left: 260px;
        }
    }




    .loginTagLine {
        max-width: 420px;

        strong {
            display: block;
            margin-bottom: 30px;
            font-size: 40px;
            font-weight: 500;
            color: #fff;
            line-height: 48px;


            @include lg-down {
                font-size: 30px;
                line-height: 38px;
                margin-bottom: 20px;
            }

            img {
                width: 35px;
                height: auto;
                display: inline-block;
                margin-left: 10px;
            }
        }

        span {
            font-size: 16px;
            color: #fff;
        }

    }
}


.loginPageRight {
    background-color: var(--main-bg-color);
    padding: 30px 15px;


    .loginSectionWrap {
        max-width: 400px;
        margin: 0 auto;
    }

    .loginTitleSec {
        margin-bottom: 30px;

        h1 {
            font-size: 28px;
            font-weight: 600;
            color: var(--app-text-color);
            display: block;
            margin-bottom: 10px;
        }

        strong {
            display: block;
            font-size: 14px;
            font-weight: 400;
            color: var(--secondary-text-color);
            margin-bottom: 10px;
        }

        p {
            font-weight: 400;
            display: block;
            color: 18181B;
        }
    }




    .rememberMe {
        color: var(--app-text-color);
        font-size: 12px !important;
        font-weight: 500 !important;
        margin-bottom: 0;
        padding-bottom: 0 !important;
        position: relative;
        padding-left: 20px;

        input {
            position: absolute;
            top: 3px;
            left: 0;
        }
    }

    .forgot {
        padding: 0px;
        text-align: right;
        display: inline-block;


        a {
            font-size: 12px;
            color: var(--app-text-color);
            font-weight: 500;
            display: inline-block;

            &:hover {
                text-decoration: underline;
            }
        }
    }



    .loginBtns {


        a {
            display: block;
            text-align: center;
            margin-bottom: 10px;
            border-radius: 5px;
            border: 1px solid var(--app-input-border-color);

            img {
                margin-right: 3px;
                display: inline-block;
                vertical-align: top;
            }
        }
    }

    // em {
    //     font-size: 40px;
    //     margin-left: 5px;
    //     margin-top: 10px;
    //     position: relative;
    //     left: 91%;
    //     bottom: 45px;

    //     &.success {
    //         color: #008A1E;
    //     }

    //     &.danger {
    //         color: #CC0000;
    //     }

    // }



    .formGroup {
        padding: 0;
        margin: 0 0 15px 0;

        label {
            display: block;
            font-size: 12px;
            text-transform: capitalize;
            color: var(--app-text-color);
            font-weight: 400;

            .checkmark {
                left: -38px !important;
            }
        }

        label.remem_style {
            margin-left: 2.4rem;
            position: relative;
        }

        .input-field {
            width: 100%;
            box-sizing: border-box;
            padding: 10px;
            border: none;
            background: var(--app-input-bg-color);
            border: 1px solid var(--app-input-border-color);
            border-radius: 5px;
            font-size: 14px;
            color: var(--app-text-color);
            font-weight: 400;
        }

    }

    .submit-btn {
        box-sizing: border-box;
        width: 100%;
        padding: 10px 10px;
        font-size: 14px;
        color: $white;
        text-align: center;
        cursor: pointer;
        border: none;
        background: #0060FF;

        @include border-radius(6px);
        @include transitions(0.5s);
        margin: 0 !important;

        &:hover {
            background: var(--primary-hover-color);
            color: #fff;
        }

        @include lg-down {
            padding: 12px 20px;
            font-size: 18px;
        }

    }


    .ssoSeprator {
        margin-bottom: 10px;
        position: relative;
        text-align: center;

        &:before {
            display: block;
            content: "";
            border-bottom: 1px solid var(--app-input-border-color);
            position: absolute;
            left: 0;
            top: 9px;
            width: 100%;
            z-index: 1;
        }

        span {
            display: inline-block;
            position: relative;
            z-index: 2;
            font-size: 14px;
            color: var(--app-text-color);
            background-color: var(--main-bg-color);
            padding: 0 15px;
        }
    }


    .login-sso-btn {
        border: 1px solid var(--border-light-color);
        border-radius: 20px;
        padding: 7px 25px;
        margin-bottom: 3px;
        cursor: pointer;
        display: inline-block;
        margin-top: 10px;

        &:hover {
            border: 1px solid var(--border-dark-color);
        }
    }



    .backToLogin {
        font-size: 14px;
        font-weight: 500;

        i {
            margin-right: 5px;
        }
    }


}



// .forgot {
//     padding: 0px;
//     text-align: right;
//     display: inline-block;

//     a {
//       font-size: 14px;
//       color: #a6a6a6;
//       font-weight: 400;
//       display: inline-block;
//     }
//   }

// .insut-fields {
//     width: 100%;
//     box-sizing: border-box;
//     padding: 10px;
//     border: none;
//     border-bottom: 1px solid #e4e4e4;
//     font-size: 16px;
//     color: #040747;
//     font-weight: 400;
//     @include placeholder($violet, 18px);
// }