
label {
    display: inline-block;
    margin-bottom: 0.5rem;
}

.mr-3, .mx-3 {
    margin-right: 1rem!important;
}

.mr-4, .mx-4 {
    margin-right: 1.5rem!important;
}

.ml-3, .mx-3 {
    margin-left: 1rem!important;
}

.text-right {
    text-align: right!important;
}
.text-left {
    text-align: left!important;
}
.pl-3, .px-3 {
    padding-left: 1rem!important;
}

.float-left {
    float: left!important;
}
.float-right {
    float: right!important;
}
.mr-2, .mx-2 {
    margin-right: 0.5rem!important;
}

.table-bordered {
    border: 1px solid #dee2e6;
}

.mb-3, .my-3 {
    margin-bottom: 1rem!important;
}


.pr-4, .px-4 {
    padding-right: 1.5rem!important;
}


.pl-2{
    padding-left: 5px;
}