@import "responsive.scss";
@import "reset.scss";


@import "@fortawesome/fontawesome-free/scss/fontawesome.scss";


// Counter



.projects-counter {
  min-height: 108px;
  margin-bottom: 15px;
  @include border-radius(8px);
  position: relative;
  border: 1px solid var(--app-dashboard-counter-border-color);

  .counter-head {
    padding: 16px 16px 12px 16px;

    .rectangle {
      width: 8px;
      height: 8px;
      border-radius: 2px;
      display: inline-block;
      margin-right: 10px;
    }

    .title {
      font-weight: 500;
    }

    .filter {
      position: relative;
      bottom: 9px;

      svg {
        width: 16px;
        height: 16px;
        fill: var(--app-text-color);
      }
    }

    &.test-scenerios {
      border-bottom: 1px solid var(--app-dashboard-counter-header-sc-border);

      .rectangle {
        background: var(--app-dashboard-counter-header-sc-rectangle);
      }
    }

    &.test-cases {
      border-bottom: 1px solid var(--app-dashboard-counter-header-tc-border);

      .rectangle {
        background: var(--app-dashboard-counter-header-tc-rectangle);
      }
    }

    &.defects {
      border-bottom: 1px solid var(--app-dashboard-counter-header-defect-border);

      .rectangle {
        background: var(--app-dashboard-counter-header-defect-rectangle);
      }
    }
  }


  &.defects-fixed {
    background: url('../images/defects-fixed.png') left bottom repeat-x #a767dd;
  }

  &.defects-cancelled {
    background: url('../images/defects-cancelled.png') left bottom repeat-x #ffc700;
  }

  .counter-inner {
    padding: 12px 16px 16px 16px;

    .counted-number {
      font-size: 28px;
      font-weight: 400;
      line-height: 32px;

      .separator {
        margin-left: 3px;
        color: var(--app-dashboard-counter-separator-color);
      }

      .sub-counter {
        margin-left: 3px;
        color: var(--app-dashboard-counter-sub-color);
        font-size: 12px;
        line-height: 0px;

        .count {
          color: var(--app-dashboard-counter-sub-color);
          position: absolute;
          bottom: 38px;
        }
      }
    }
  }
}



// Dashboard Tabs



.dashboardTabs {

  .mat-tab-header,
  .mat-mdc-tab-header {
    overflow: visible !important;
    border-bottom: 1px solid var(--app-dashboard-counter-border-color);
  }

  .mat-tab-label-container,
  .mat-mdc-tab-label-container {
    overflow: visible !important;
  }

  .mat-tab-labels,
  .mat-mdc-tab-labels {

    max-width: max-content;
    flex-wrap: wrap;

    .mat-tab-label,
    .mat-mdc-tab {
      overflow: visible !important;
      padding: 0 !important;
      margin: 0 15px 0 0 !important;
      display: inline-flex;



      .mat-tab-label-content,
      .mdc-tab__text-label {
        font-size: 14px;
        font-weight: 500;
        color: var(--app-text-color) !important;

        img {
          display: inline-block;
          margin-right: 15px;
          height: 22px;
          width: auto;
          filter: var(--app-image-gray);
        }
      }

      &.mat-tab-label-active,
      &.mdc-tab--active {
        border-bottom: 2px solid var(--app-dashboard-tab-border-dark);
      }
    }
  }



  .mat-tab-list,
  .mat-mdc-tab-list {
    .mdc-tab-indicator {
      display: none !important;
    }
  }


}





// Dashboard Graphs





.defects-by-severity {
  border: 1px solid var(--graph-box-border-color);
  background: var(--graph-box-bg-color);
  padding: 20px;
  padding-top: 30px;
  position: relative;
  margin-bottom: 30px;

  @media only screen and (min-width: 992px) {
    margin-bottom: 0px;
  }

  h2 {
    color: var(--primary-color);
    font-size: 18px;
    font-weight: 500;
    display: inline-block;
  }
}

.severity-filter {
  // position: absolute;
  // right: 70px;
  // top: 32px;
  border-radius: 4px;
  font-size: 14px;
  padding: 8px 7px;
  background: var(--primary-color);
}

// ------------------------------------------


.statistics {
  border: 1px solid var(--graph-box-border-color);
  background: var(--graph-box-bg-color);
  padding: 20px;
  padding-top: 30px;
  position: relative;
  // min-height: calc(100% - 20px);
  margin-bottom: 30px;
  border-radius: 8px;

  h2 {
    color: var(--primary-color);
    font-size: 18px;
    font-weight: 500;
    display: inline-block;
  }
}


// .statistics {
//   border:1px solid var(--graph-box-border-color);
//   background: var(--graph-box-bg-color);
//   padding: 20px;

//   position: relative;
//   min-height: calc(100% - 20px);
//   h2 {
//     color:var(--primary-color);
//     font-size: 18px;
//     font-weight: 500;

//   }
// }



.severity-filter {
  border-radius: 5px;
  font-size: 14px;
  padding: 8px 7px;
}


.severity-filter {
  background: var(--primary-color);
}



// ------------------------------------------



// .statistics {
//   background: var(--app-chart-background-color);
//   padding: 20px;
//   padding-top: 37px;
//   position: relative;
//    min-height: calc(100% - 0px);
//   h2 {
//     color: #3577e4;
//     font-size: 18px;
//     font-weight: 500;

//   }
// }

.pt-2rem {
  padding-top: 2rem;
}


.export-bn {
  padding: 7px 15px;
}



// ------------------------------------------


.test-cases-status {
  border: 1px solid var(--graph-box-border-color);
  background: var(--graph-box-bg-color);
  padding: 20px;
  padding-top: 30px;
  position: relative;
  margin-bottom: 30px;
  border-radius: 8px;

  @media only screen and (min-width: 992px) {
    margin-bottom: 0px;
  }

  h2 {
    color: var(--primary-color);
    font-size: 18px;
    font-weight: 500;
    display: inline-block;
  }
}

// ------------------------------------------




.executed-test-cases {
  border: 1px solid var(--graph-box-border-color);
  background: var(--graph-box-bg-color);
  padding: 20px;
  padding-top: 30px;
  position: relative;
  min-height: calc(100% - 0px);
  z-index: 3;

  h2 {
    color: var(--primary-color);
    font-size: 18px;
    font-weight: 500;
    display: inline-block;

  }
}



// .statistics {
//   background: var(--app-chart-background-color);
//   padding: 20px;
//   padding-top: 37px;
//   position: relative;
//   h2 {
//     color: #3577e4;
//     font-size: 18px;
//     font-weight: 500;

//   }
// }

// .pt-2rem{
//   padding-top: 2rem;
// }


// .export-bn{
//     padding: 7px 15px;
// }




.dashboard-loader-container {
  text-align: center;
  padding-top: 150px !important;
}

.dashboard-loader-container i {
  font-size: 25px !important;
}

.dashboard-loader-container.padding-botton {
  padding-bottom: 150px !important;
}







/*insights page graphs*/




.insightGraphBox {

  border: 1px solid var(--graph-box-border-color);
  background: var(--graph-box-bg-color);
  padding: 20px;
  position: relative;
  margin-bottom: 20px;
  min-height: calc(100% - 20px);



  .insightGraphHead {
    h2 {
      color: var(--primary-color);
      font-size: 18px;
      font-weight: 500;
      display: inline-block;
    }
  }


  .insightGraph {}

  .insightGraphDesc {
    min-height: 65px;
    font-size: 13px;
    color: #ccc;
    padding-top: 10px;

    p {
      padding-left: 20px;
      position: relative;
      margin: 0;
      font-size: 13px;
      color: var(--app-text-color);

    }

    i {
      position: absolute;
      left: 0;
      top: 3px;
      color: var(--primary-color);
      margin-right: 6px;
    }
  }
}




// Global Dashbord



.graphWrap {
  background: var(--graph-box-bg-color);
  border: 1px solid var(--graph-box-border-color);
  min-height: 200px;
  height: calc(100% - 40px);
  margin-bottom: 30px;
  padding: 20px;
  border-radius: 8px;

  h3 {
    color: var(--primary-color);
    font-size: 18px;
    font-weight: 500;
    display: inline-block;

    &.text-center {
      display: block;
      text-align: center;
    }
  }

  .graphWrapInner {
    max-width: 800px;
    margin: 0 auto;
  }
}


.pdf-chart {
  position: absolute;
  top: 11px;
  right: 50px;
  border: none;
  outline: none;
  padding: 0;
  background: none;

  img {
    width: 20px;
    height: 20px;
  }

}

.customGraphDeleteBtn {
  position: absolute;
  top: 18px;
  right: 30px;
  font-size: 8px;
  font-weight: 500;
  border: none;
  outline: none;
  border-radius: 3px;
  width: 30px;
  height: 30px;
  text-align: center;
  background: #ff5959;
  color: #fff;
  padding: 0;
  z-index: 1;

  &:hover {
    background: #ff4f4f;
    color: #fff;
  }

  i {
    font-size: 11px !important;
    color: #fff;
  }
}


.customGraphExportBtn {
  position: absolute;
  top: 24px;
  right: 76px;
  font-weight: 500;
  border: none;
  outline: none;
  border-radius: 3px;
  text-align: center;
  color: var(--app-text-color);
  z-index: 1;
  font-size: 22px;

  i {
    font-size: 22px;
    color: var(--app-text-color);
  }

  &:hover {
    background-color: none;
    color: var(--app-text-color);
  }
}




.globalDbTableWrap {
  background: var(--box-bg-color);
  border: 1px solid var(--box-border-color);
  padding: 20px;
  margin-bottom: 20px;

  h3 {
    text-align: left;
    color: var(--primary-color);
    font-size: 18px;
    font-weight: 500;
    display: inline-block;

  }
}





.globalDbTable {
  background: var(--table-bg-color);
  max-height: 300px;
  overflow: auto;
  border: 1px solid var(--table-border-color);

  border-color: var(--table-border-color) !important;

  /* width */
  &::-webkit-scrollbar {
    width: 6px;
    border-radius: 6px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: var(--scrollbar-track-color);
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: var(--scrollbar-thumb-color);
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: var(--scrollbar-thumb-hover-color);
  }

  scrollbar-color: var(--scrollbar-track-color) var(--scrollbar-thumb-color);
  scrollbar-width: thin;



  table {
    margin: 0;
    background-color: var(--table-bg-color);
    border-color: var(--table-border-color) !important;

    tr {

      th,
      td {
        background: none;
        padding: 10px 15px;
        font-size: 14px;
        color: var(--text-color);
        border-color: var(--table-border-color);
      }

      &:hover {
        background-color: var(--table-highlight-bg-color);
      }

      th {
        background: var(--table-th-bg-color);
        font-weight: 500;
      }
    }

  }
}