$minWidthCell:320px;
$minWidthMobile:767px;
$minWidthiPad:769px;
$minWidthDesktop:992px;
$minWidthXlDesktop:1200px;
$minWidthSlDesktop:1400px;

$minWidthXxlDesktop:1600px;

$minWidthXxxlDesktop:1800px;


// mobile screens breakpoint mixin //
@mixin cell {
  @media only screen and (min-width: #{$minWidthCell}) and (max-width: #{$minWidthiPad}) {
    @content;
  }
}
// mobile screens breakpoint mixin //
@mixin mobile {
  @media only screen and (min-width: #{$minWidthMobile}) {
    @content;
  }
}
@mixin mobilePhone {
  @media only screen and (min-width: #{$minWidthCell}) {
    @content;
  }
}

// tablet width breakpoint mixin //
@mixin tablet {
    @media only screen and (min-width: #{$minWidthiPad}) {
      @content;
    }
}

// desktop width breakpoint mixin //
@mixin desktop {
    @media only screen and (min-width: #{$minWidthDesktop}) {
      @content;
    }
}

@mixin xl-desktop {
  @media only screen and (min-width: #{$minWidthXlDesktop}) {
    @content;
  }
}


@mixin sl-desktop {
  @media only screen and (min-width: #{$minWidthSlDesktop}) {
    @content;
  }
}

@mixin xxl-desktop {
  @media only screen and (min-width: #{$minWidthXxlDesktop}) {
    @content;
  }
}

@mixin xxxl-desktop {
  @media only screen and (min-width: #{$minWidthXxxlDesktop}) {
    @content;
  }
}




/*bilal*/



/*Bootstrap Breakpoints*/
$xxlWidth:1366px;
$xlWidth:1200px;
$lgWidth:992px;
$mdWidth:767px;
$smWidth:576px;


/*Bootstrap Media Min Width*/

@mixin xxl-up {
    @media (min-width: #{$xxlWidth}) { 
        @content;
    }
}

@mixin xl-up {
    @media (min-width: #{$xlWidth}) { 
        @content;
    }
}
@mixin lg-up {
    @media (min-width: #{$lgWidth}) { 
        @content;
    }
}
@mixin md-up {
    @media (min-width: #{$mdWidth}) { 
        @content;
    }
}
@mixin sm-up {
    @media (min-width: #{$smWidth}) { 
        @content;
    }
}

/*Bootstrap Media Max Width*/

@mixin xxl-down {
    @media (max-width: #{$xxlWidth}) { 
        @content;
    }
}

@mixin xl-down {
    @media (max-width: #{$xlWidth}) { 
        @content;
    }
}
@mixin lg-down {
    @media (max-width: #{$lgWidth}) { 
        @content;
    }
}
@mixin md-down {
    @media (max-width: #{$mdWidth}) { 
        @content;
    }
}
@mixin sm-down {
    @media (max-width: #{$smWidth}) { 
        @content;
    }
}
 
/*Bootstrap Media Only ( Min and Max ) Width*/


@mixin xl-only {
   @media (min-width: #{$xlWidth}) {
        @content;
    }
}
@mixin lg-only {
  @media (min-width: #{$lgWidth}) and (max-width: #{$xlWidth - 0.2px}) {
        @content;
    }
}
@mixin md-only {
    @media (min-width: #{$mdWidth}) and (max-width: #{$lgWidth - 0.2px}) {
        @content;
    }
}
@mixin sm-only {
    @media (min-width: #{$smWidth}) and (max-width: #{$mdWidth - 0.2px}) {
        @content;
    }
}
@mixin xs-only{
    @media (max-width: #{$smWidth}) { 
         @content;
    }
}