 @mixin theme($theme: light) {
   @if $theme ==dark {
     --app-background-color: #20254d;
     --app-sidebar-color: #171b40;
     --app-sidebar-actions-color: rgba(13, 110, 253, 0.25);
     --app-header-button-color: #575fa8;

     --app-header-project-arrow-img: url(./assets/images/dropdown-arrow-grey.png);
     --app-header-user-guide-color: white;
     --app-button-theme-color: #575fa8;
     --app-nav-selected-color: #343865;
     --app-datatable-odd-row-color: #171b40;
     --app-datatable-even-row-color: #171b401c;
     --app-datatable-row-hover-color: #767dc6;
     --app-datatable-action-button-color: #52589c;
     --app-datatable-footer-button-color: #575fa8;
     --app-form-control-color: #575fa8;
     --app-form-control-disabled-color: #767c83;
     --app-checkbox-button-color: #52589c;
     --app-dropdown-arrow-img: url(./assets/images/dropdown-arrow-grey.png);
     --app-editor-button-color: #343865;
     --app-background-white-alternate-color: #343865;
     --app-background-gray-alternate-color: transparent;
     --app-modal-body-color: #20254d;
     --app-modal-table-head-color: #171b40;
     --app-modal-table-body-color: transparent;
     --app-button-project-class-color: #fff;
     --app-ngxpicker-table-color: #20254d;
     --app-ngxpicker-table-head-color: #d1e7dd;
     --app-tree-button-color: #fff;
     --app-tree-button-hover-color: #3577e4;
     --app-mat-table-header-footer-color: #171b40;
     --app-mat-table-body-color: #20254d;
     --app-mat-table-row-hover-color: #767dc6;
     --app-mat-table-footer-disabled-color: #ffffff70;
     --app-mat-table-filter-button-img: url(./assets/images/filter_btn.png);
     --app-mat-table-filter-body-color: #575fa8;
     --app-mat-table-columns-button-color: #373f9d;
     --app-mat-table-columns-button-img: url(./assets/images/tab-columns.png);
     --app-testcase-steps-container-color: #343865;
     --app-attachment-browse-button-color: #575fa8;
     --app-details-table-th-color: #20254d;
     --app-details-table-section-color: #575fa8;
     --app-details-comment-body-color: #20254d;
     --app-details-tab-color: #575fa8;
     --app-details-tab-heading-color: #fff;
     --app-cycle-lable-tree-container-color: #575fa829;
     --app-cycle-lab-cycle-img: url(./assets/images/tree-icons/cycle-gray.png);
     // --app-popover-color: #343865;
     --app-task-add-card-button-color: #575fa8;
     --app-task-card-color: #343865;
     --app-task-inner-card-color: #20254d;
     --app-task-calender-today-color: #575fa8;
     --app-task-calender-row-hover-color: #242952;
     --app-task-calender-col-hover-color: #20254d;
     --app-integeration-edit-table-col-color: #343865;
     --app-reports-panel-body-color: #343865;
     --app-reports-form-control-color: #575fa8;
     --app-reports-select-option-color: #575fa8;
     --app-reports-template-control-color: #575fa8;
     --app-report-schedule-create-body-color: #171b40;
     --app-dashboard-background-color: #20254d;
     --app-chart-background-color: #171b40;
     --app-customize-table-color: #171b40;
     --app-global-dashboard-table-th-color: #3577e4;
     --app-global-dashboard-table-body-color: #343865;
     --app-insight-section-color: #171b40;
     --app-billing-detail-color: #fff;
     --app-billing-table-head-color: #171b40;
     --app-billing-table-odd-row-color: #343865;
     --app-billing-table-even-row-color: #171b40;
     --app-billing-upgrade-plan-total-color: #343865;
     --app-billing-downgrade-h4-color: #2433dc;
     --app-overlay-color: #343865;
     --app-overlay-table-th-color: #20254d;
     --app-overlay-table-section-color: #575fa8;

     --app-border-color: #424a89;



     // --------------new variables------------------


     --primary-color: #565FA8;
     --primary-hover-color: #4752A6;
     --primary-text-color: #868fd4;
     --secondary-text-color: #E9EAEC;



     --main-bg-color: #20254D;
     --sidebar-bg-color: #20254D;
     --header-bg-color: #20254D;
     --footer-bg-color: #20254D;
     --overlay-bg-color: #20254D;
     --modal-bg-color: #20254D;

     --main-nav-bg: #3B85FF;

     --app-highlight-bg-color: #242952;
     --app-highlight-bg-dark-color: #2c3261;

     --form-bg-color: #FFFFFF;
     --input-bg-color: #FFFFFF;
     --input-border-color: #CED4DA;
     --input-hover-color: #FFFFFF;
     --input-hover-border-color: #B6D1FF;



     // text color

     --heading-color: #fff;

     --text-color: #fff;
     --app-text-color: #fff;
     --link-color: #3B85FF;

     --border-light-color: #363B68;
     --border-dark-color: #565FA8;

     --box-bg-color: #1D2248;
     --box-border-color: #363B68;

     --comp-bg-color-light: #2d325f;

     --comp-bg-color: #1D2248;
     --comp-border-color: #363B68;

     //  Ai combinations
     --ai-btn-bg-color: #7C32DD;
     --ai-credit-bg: #20254d;

     // Dashboard Graphs

     --graph-box-bg-color: #1D2248;
     --graph-bg-color: #1D2248;
     --graph-box-border-color: #363B68;

     --chart-line-color: #363B68;

     // Tables

     --table-bg-color: #20254D;
     --thead-bg-color: #181D44;
     --tfoot-bg-color: #181D44;
     --table-th-bg-color: #181D44;
     --table-border-color: #363B68;
     --mat-table-paginator-bg: #181D44;
     // --table-tr-bg-color:;
     --table-highlight-bg-color: #242952;
     --table-cta-bg: #181D44;
     --table-cta-bg-hover: #101430;

     // scroll bar

     --scrollbar-track-color: #181D44;
     --scrollbar-thumb-color: #363B68;
     --scrollbar-thumb-hover-color: #535A90;

     // App Dropdowns

     --app-dropdown-bg-color: #242952;


     // App Form


     --app-input-bg-color: #20254D;
     --app-input-border-color: #3A3F72;
     --app-input-border-focus-color: #5C629F;
     --select-dropdown-bg-color: #20254D;

     --input-text-color: #565FA8;

     --input-select-tag-bg-color: #565FA8;
     --input-select-tag-hover-bg-color: #6B76CC;


     --app-input-placeholder-color: #ffffffab;

     //  Toolip 

     --app-tooltip-bg-color: #1D2248;
     --app-tooltip-border-color: #363B68;


     //  Accordian 


     --app-accordian-bg-color: #025F5B;
     --app-accordian-hover-bg-color: #016B66;
     --app-accordian-active-bg-color: #016B66;

     //  tabs 


     --app-tab-bg-color: #025F5B;
     --app-tab-hover-bg-color: #016B66;
     --app-tab-active-bg-color: #016B66;
     --app-tab-text-color: #fff;



     //  overlay 

     --app-overlay-bg-color: #1D2248;
     --app-overlay-border-color: #363B68;


     //Image Filter

     --app-image-filter: invert(0.2) sepia(5) saturate(5) hue-rotate(191deg);
     --app-image-gray: brightness(0) invert(1);
     --app-image-white: invert(1);


     //Expire Popup Ccolor

     --ex-defects-bg-color: #E55353;
     --ex-defects-text-color: #fff;

     --ex-tc-bg-color: #2EB955;
     --ex-tc-text-color: #fff;

     --ex-te-bg-color: #C26A19;
     --ex-te-text-color: #fff;

     //dashboard colors
     --app-dashboard-counter-border-color: #FFFFFF33;
     --app-dashboard-counter-header-sc-border: #290E51;
     --app-dashboard-counter-header-sc-rectangle: #5003C1;
     --app-dashboard-counter-header-tc-border: #0B4919;
     --app-dashboard-counter-header-tc-rectangle: #03781D;
     --app-dashboard-counter-header-te-border: #0B367E;
     --app-dashboard-counter-header-te-rectangle: #0355DC;
     --app-dashboard-counter-header-defect-border: #700B0B;
     --app-dashboard-counter-header-defect-rectangle: #D72B24;
     --app-dashboard-counter-separator-color: #28282C;
     --app-dashboard-counter-sub-color: #909299;
     --app-dashboard-tab-border-dark: #797B88;
     --app-layout-project-dd-color: #111A2C;
     --app-layout-check-list-color: #984DEF;
     --app-layout-check-list-bg-color: #181127;
     --app-layout-theme-circle-bg-color: #38383D;
     --app-header-trial-days-color: #B1B4BD;
     --app-dashboard-resend-email-warning-color: #C17603;
     --app-dashboard-resend-email-warning-bg-color: #271D11;
     --app-dashboard-resend-email-warning-text-color: #D79624;

     //drag drop css
     --app-drag-drop-popup-border-color: #3D4585;
     --app-drag-drop-popup-disable-button: #2D3362;
     --app-drag-drop-popup-drag-border-color: #48508E;
     --app-drag-drop-popup-highlight-color: #0E2B5B;
     --app-drag-drop-popup-icon-color: #fff;
     --app-drag-drop-popup-cancel-btn-color: #FFFFFF33;

     //import validation screen css
     --app-import-validation-success-background-color: #111A14;
     --app-import-validation-warning-background-color: #271D11;
     --app-import-validation-error-background-color: #271113;
     --app-import-validation-errors-border-color: #48508E;
     --app-import-validation-success-color: #33A14B;
     --app-import-validation-warning-color: #E08700;
     --app-import-validation-error-color: #EA5454;

     //import map screen
     --app-import--map-select-background-color: #3D4585;
     --app-import--map-select-error-background-color: #3E0F11;

     //integration map screen
     --app-map-projects-background-color: #333A70;
     --app-map-projects-btn-border-color: #FFFFFF33;
   }


   @else {




     // New Variables


     --primary-color: #3B85FF;
     --primary-hover-color: #2F77EE;
     --primary-text-color: #3B85FF;
     --secondary-text-color: #4C4E56;


     --main-bg-color: #FFFFFF;
     --sidebar-bg-color: #FFFFFF;
     --header-bg-color: #FFFFFF;
     --footer-bg-color: #FFFFFF;
     --overlay-bg-color: #FFFFFF;
     --modal-bg-color: #FFFFFF;

     --main-nav-bg: #3B85FF;

     --app-highlight-bg-color: #F7FAFF;
     --app-highlight-bg-dark-color: #f4f8ff;


     --form-bg-color: #FFFFFF;
     --input-bg-color: #FFFFFF;
     --input-border-color: #CED4DA;
     --input-hover-color: #FFFFFF;
     --input-hover-border-color: #B6D1FF;

     // text color

     --heading-color: #333333;

     --text-color: #333333;
     --app-text-color: #333333;
     --link-color: #3B85FF;

     --border-light-color: #F0F0F0;
     --border-dark-color: #CCCCCC;


     --box-bg-color: #F9F9F9;
     --box-border-color: #F0F0F0;


     --comp-bg-color-light: #efefef;

     --comp-bg-color: #F9F9F9;
     --comp-border-color: #F0F0F0;

     //  Ai combinations
     --ai-btn-bg-color: #7B33E6;
     --ai-credit-bg: #E6EFFF;


     // Dashboard Graphs

     --graph-box-bg-color: #F9F9F9;
     --graph-bg-color: #F9F9F9;
     --graph-box-border-color: #F0F0F0;


     --chart-line-color: #CCCCCC;

     // Tables

     --table-bg-color: #FFFFFF;
     --thead-bg-color: #F9F9F9;
     --tfoot-bg-color: #F9F9F9;
     --table-th-bg-color: #F9F9F9;
     --table-border-color: #F0F0F0;
     --mat-table-paginator-bg: #F9F9F9;
     // --table-tr-bg-color:;
     --table-highlight-bg-color: #F7FAFF;

     --table-cta-bg: #F0F0F0;
     --table-cta-bg-hover: #D8D8D8;


     // scroll bar

     --scrollbar-track-color: #eeeeee;
     --scrollbar-thumb-color: #dedede;
     --scrollbar-thumb-hover-color: #d3d3d3;

     // App Dropdowns

     --app-dropdown-bg-color: #F9F9F9;


     // App Form


     --app-input-bg-color: #fff;
     --app-input-border-color: #E9EAEC;
     --app-input-border-focus-color: #B6D1FF;
     --select-dropdown-bg-color: #fff;

     --input-select-tag-bg-color: #F0F0F0;
     --input-select-tag-hover-bg-color: #CCCCCC;


     --app-input-placeholder-color: #0000008f;


     //  Toolip 

     --app-tooltip-bg-color: #F9F9F9;
     --app-tooltip-border-color: #F0F0F0;

     //  Accordian 


     --app-accordian-bg-color: #494958;
     --app-accordian-hover-bg-color: #3C3C4A;
     --app-accordian-active-bg-color: #3C3C4A;



     //  tabs 


     --app-tab-bg-color: #494958;
     --app-tab-hover-bg-color: #3C3C4A;
     --app-tab-active-bg-color: #3C3C4A;
     --app-tab-text-color: #fff;



     //  overlay 

     --app-overlay-bg-color: #ffffff;
     --app-overlay-border-color: #F0F0F0;


     //Image Filter

     --app-image-filter: inherit;
     --app-image-gray: inherit;
     --app-image-white: inherit;



     //Expire Popup Ccolor

     --ex-defects-bg-color: #FFF6F6;
     --ex-defects-text-color: #E55353;
     --ex-tc-bg-color: #F1FFF5;
     --ex-tc-text-color: #2EB955;
     --ex-te-bg-color: #FFFEF0;
     --ex-te-text-color: #C26A19;



     //------------------------------------------

     --app-background-color: #fff;
     --app-sidebar-color: #fff;
     --app-sidebar-actions-color: #f2f1fd;
     --app-header-button-color: #{$lightGrey};

     --app-header-project-arrow-img: url(./assets/images/select-arrow.png);
     --app-header-user-guide-color: #3577e4;
     --app-button-theme-color: #3577e4;
     --app-nav-selected-color: #1f56b2;
     --app-datatable-odd-row-color: #f0f0f0;
     --app-datatable-even-row-color: #e4e4e4;
     --app-datatable-row-hover-color: #f5f5f5;
     --app-datatable-action-button-color: white;
     --app-datatable-footer-button-color: #e4e4e4;
     --app-form-control-color: #fff;
     --app-form-control-disabled-color: #e9ecef;
     --app-checkbox-button-color: #fff;
     --app-dropdown-arrow-img: url(./assets/images/dropdown-arrow.png);
     --app-editor-button-color: #c8cbcf;
     --app-background-white-alternate-color: #fff;
     --app-background-gray-alternate-color: #f0f0f0;
     --app-modal-body-color: #fff;
     --app-modal-table-head-color: #f0f0f0;
     --app-modal-table-body-color: #fff;
     --app-button-project-class-color: #3577e4;
     --app-ngxpicker-table-color: #fff;
     --app-ngxpicker-table-head-color: #988c8c;

     --app-tree-button-color: #3577e4;
     --app-tree-button-hover-color: #0C0E4B;

     --app-mat-table-header-footer-color: #f0f0f0;
     --app-mat-table-body-color: #fff;

     --app-mat-table-row-hover-color: #f5f5f5;
     --app-mat-table-footer-disabled-color: #0000001f;
     --app-mat-table-filter-button-img: url(./assets/images/filter_btn_blue.png);
     --app-mat-table-filter-body-color: #fff;
     --app-mat-table-columns-button-color: #f0f0f0;
     --app-mat-table-columns-button-img: url(./assets/images/tab-columns-gray.png);
     --app-testcase-steps-container-color: #f8f8f9;
     --app-attachment-browse-button-color: #e9ecef;


     --app-details-table-th-color: #fbfbfb;
     --app-details-comment-body-color: #f5f5f5;
     --app-details-tab-color: #f0f0f0;
     --app-details-tab-heading-color: #3f70c0;
     --app-details-table-section-color: #eee;
     --app-cycle-lable-tree-container-color: #fcfcfc;
     --app-cycle-lab-cycle-img: url(./assets/images/tree-icons/cycle.png);
     // --app-popover-color: #fff;
     --app-task-add-card-button-color: #f0f0f0;
     --app-task-card-color: #f0f0f0;
     --app-task-inner-card-color: #fff;
     --app-task-calender-today-color: #e8fde7;
     --app-task-calender-row-hover-color: #F7FAFF;
     --app-task-calender-col-hover-color: #ededed;
     --app-integeration-edit-table-col-color: #f3f3f3;
     --app-reports-panel-body-color: #373737;
     --app-reports-form-control-color: #373737;
     --app-reports-select-option-color: transparent;
     --app-reports-template-control-color: #fff;
     --app-report-schedule-create-body-color: #f5f5f5;
     --app-dashboard-background-color: #f5f5f5;
     --app-chart-background-color: #fff;
     --app-customize-table-color: #f8f8f8;
     --app-global-dashboard-table-th-color: #e7e7e7;
     --app-global-dashboard-table-body-color: #f8f8f8;
     --app-insight-section-color: #fdfdfd;
     --app-billing-detail-color: gray;
     --app-billing-table-head-color: #cecece;
     --app-billing-table-odd-row-color: #fff;
     --app-billing-table-even-row-color: #e8e8e8;
     --app-billing-upgrade-plan-total-color: #f0f0f0;
     --app-billing-downgrade-h4-color: #2a295b;
     --app-overlay-color: #fff;
     --app-overlay-table-th-color: #fbfbfb;
     --app-overlay-table-section-color: #eee;
     --app-border-color: #eaeaea;

     //dashboard colors
     --app-dashboard-counter-border-color: #0000001A;
     --app-dashboard-counter-header-sc-border: #B38AF1;
     --app-dashboard-counter-header-sc-rectangle: #5A00E0;
     --app-dashboard-counter-header-tc-border: #54B168;
     --app-dashboard-counter-header-tc-rectangle: #008A1E;
     --app-dashboard-counter-header-te-border: #5494FF;
     --app-dashboard-counter-header-te-rectangle: #0060FF;
     --app-dashboard-counter-header-defect-border: #EA5454;
     --app-dashboard-counter-header-defect-rectangle: #CC0000;
     --app-dashboard-counter-separator-color: #E9EAEC;
     --app-dashboard-counter-sub-color: #7E808E;
     --app-dashboard-tab-border-dark: #8B8D9C;
     --app-layout-project-dd-color: #E6EFFF;
     --app-layout-check-list-color: #40009F;
     --app-layout-check-list-bg-color: #EFE6FC;
     --app-layout-theme-circle-bg-color: #CACBD1;
     --app-header-trial-days-color: #4C4E56;
     --app-dashboard-resend-email-warning-color: #E08700;
     --app-dashboard-resend-email-warning-bg-color: #FCF3E6;
     --app-dashboard-resend-email-warning-text-color: #A56503;

     //import drag drop css
     --app-drag-drop-popup-border-color: #E9EAEC;
     --app-drag-drop-popup-disable-button: #E9EAEC;
     --app-drag-drop-popup-drag-border-color: #8B8D9C;
     --app-drag-drop-popup-highlight-color: #E6EFFF;
     --app-drag-drop-popup-icon-color: #63646F;
     --app-drag-drop-popup-cancel-btn-color: #0000001A;

     //import validation screen css
     --app-import-validation-success-background-color: #E6F3E9;
     --app-import-validation-warning-background-color: #FCF3E6;
     --app-import-validation-error-background-color: #FCE6E6;
     --app-import-validation-errors-border-color: #8B8D9C;
     --app-import-validation-success-color: #007E1B;
     --app-import-validation-warning-color: #A56503;
     --app-import-validation-error-color: #CC0000;

     //import map screen
     --app-import--map-select-background-color: #E9EAEC;
     --app-import--map-select-error-background-color: #FCE6E6;

     //integration map screen
     --app-map-projects-background-color: #F7F7F8;
     --app-map-projects-btn-border-color: #0000001A;

   }
 }