@import "reset.scss";



.mat-expansion-panel {
  background: none !important;

  &.mat-expanded,
  &.mat-expansion-panel-spacing {
    overflow: visible !important;
  }

  .form-check-label {
    color: var(--app-text-color);
  }

  .app-audit-trail-revision-history-list {
    color: transparent;
  }
}

.mat-expansion-panel-header {
  background: var(--app-accordian-bg-color) !important;
  color: #fff !important;
  border-radius: 3px;

  .mat-panel-title,
  .mat-expansion-panel-header-title,
  .mat-expansion-panel-header-title span {
    color: #fff !important;
  }

  &:hover,
  &:focus {
    background: var(--app-accordian-hover-bg-color) !important;
    color: #fff !important;

    .mat-panel-title,
    .mat-expansion-panel-header-title,
    .mat-expansion-panel-header-title span {
      color: #fff !important;
    }
  }
}


.mat-expansion-panel-header.mat-expanded {
  background: var(--app-accordian-bg-color) !important;
  color: #fff !important;

  .mat-panel-title,
  .mat-expansion-panel-header-title {
    color: #fff !important;
  }

  &:hover,
  &:focus {
    background: var(--app-accordian-hover-bg-color) !important;
    color: #fff !important;

    .mat-panel-title,
    .mat-expansion-panel-header-title {
      color: #fff !important;
    }
  }
}







.mat-expansion-panel:not([class*=mat-elevation-z]) {
  box-shadow: none !important;
}

.mat-expansion-panel-header {
  background: var(--app-accordian-bg-color) !important;
  height: auto !important;
  padding: 10px 15px 10px !important;
  position: relative;

  &:last-child {
    border-bottom: none;
  }

  & .mat-expansion-indicator {
    display: none !important;
  }

  &:after {
    content: "+";
    position: relative;
    background: $white;
    padding: 5px 3px 5px;
    font-family: $font;
    font-size: 27px;
    color: $violet;
    height: auto;
    line-height: 10px;
    font-weight: 300;
  }

  &.mat-expanded {
    background: var(--app-accordian-active-bg-color) !important;
    border-radius: 3px;
    height: auto !important;

    &:after {
      content: "-";
      position: relative;
      background: $white;
      padding: 4px 6px 5px;
      font-family: $font;
      font-size: 35px;
      color: #373737;
      height: auto;
      line-height: 10px;
      font-weight: 300;
    }
  }
}

.mat-expansion-panel-header-title {
  font-size: 15px;
}

.mat-expansion-panel-spacing {
  margin: 0px !important;
}

.mat-expansion-panel-body {
  // background: var(--app-details-comment-body-color);
  // padding: 20px 20px 5px !important;
  position: relative;
  padding: 10px 0 0 0 !important;


}

.mat-expansion-panel-header[aria-disabled=true] {
  opacity: 0.5;
}