// @import url('https://fonts.googleapis.com/css?family=Roboto:100,100i,300,300i,400,400i,500,500i,700,700i,900');
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,400&display=swap');
// @import url('https://fonts.googleapis.com/css?family=Lato:300,300i,400,400i,700,700i');



// generice variables //
$violet: #3577e4;
$orangeRoughy: #3577e4;
$cinnaBar: #3577e4;
$darkGrey: #4d4d4d;
$cloudBurst: #3577e4;
$green: #39b54a;
$darkGreen: #6dac29;
$lightGrey: #f0f0f0;

$white: #ffffff;
$offWhite: #f8f8f8;
$black: #000;

$font: "Roboto", sans-serif;
$lato: "Roboto", sans-serif;

 
$error: #f00;



// background size cover //
@mixin cover-background($cover) {
    -webkit-background-size:$cover;
    -moz-background-size:$cover;
    -o-background-size:$cover;
    background-size:$cover;
}
// all transitions //
@mixin transitions($duration) {
    -webkit-transition: all $duration; /* Safari */
    transition: all $duration;
}
// placeholder color //
@mixin placeholder($color,$fontSize){
    &::-webkit-input-placeholder {
        color:$color;
        font-size: $fontSize;
    }
    &::-moz-placeholder {
        color:$color;
        font-size: $fontSize;
    }
    &:-ms-input-placeholder {
        color:$color;
        font-size: $fontSize;
    }
    &:-moz-placeholder {
        color:$color;
        font-size: $fontSize;
    }
}
// border radius //
@mixin border-radius($radius){
    -webkit-border-radius: $radius;
    -moz-border-radius: $radius;
    border-radius: $radius;
}

.mt-6{
    margin-top: 6rem;
}
.payfees{pointer-events:none !important; background: lightgray !important;}
.p_label {line-height:3; padding-bottom: 0;}
.mappig-jira{ margin-bottom: 10px;}




// New Bilal


 